import BaseInput from "@components/Inputs/BaseInput"
import { Controller } from "react-hook-form"

const LegalOwner = ({ type }: { type: 'creative-works' | 'release' }) => {
  const validateYear = (value: string) => {

    if (value.length < 4) {
      return 'Year must be 4 digit'
    }

    const year = Number(value)
    if (Number.isNaN(year)) {
      return 'Year must be a number'
    }

    if (year <= 1900) {
      return 'Year must be greater than or equal to 1900'
    }
    if (year > new Date().getFullYear()) {
      return 'Year must be less than or equal to current year'
    }

    return true
  }

  return (
    <div>
      <label className="label font-semibold pt-0">
        <span className="label-text">{type === 'creative-works' ? '©' : 'Ⓟ'} Legal Owner ({type === 'creative-works' ? 'Creative Works' : 'Release'})</span>
      </label>
      <div className="flex gap-4">
        <Controller
          name={`${type}.year`}
          defaultValue=""
          rules={{ required: { value: true, message: 'Year is required!' }, validate: validateYear }}
          render={({ field, formState: { errors } }) => (
            <div className="flex-1">
              <BaseInput {...field} placeholder="Year" />
              <p className="text-xs text-error my-1">{errors?.[type]?.year?.message}</p>
            </div>
          )}
        />
        <Controller
          name={`${type}.name`}
          defaultValue=""
          render={({ field }) => (
            <div className="flex-1">
              <BaseInput {...field} placeholder="Name" />
            </div>
          )}
        />
      </div>
    </div>
  )
}

export default LegalOwner