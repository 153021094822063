import DropdownMenu from "@components/DropdownMenu";
import Image from "@components/Image";
import { forwardRef } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";

type VideoCardProps = {
  id: string;
  title: string;
  description: string;
  onDelete?: (id: string) => void;
  onOpen?: (streamId: string) => void;
}

const VideoCard = forwardRef<HTMLDivElement, VideoCardProps>(({ id, title, description, onDelete, onOpen }, ref) => {

  return (
    <div key={id} className="relative max-w-lg" ref={ref}>
      <div className="absolute top-2 right-2 z-10">
        <DropdownMenu
          menuItems={[
            {
              label: "Delete",
              icon: <MdDelete size={18} />,
              action: () => {
                onDelete?.(id);
              },
            },
          ]}
        >
          <a className="btn btn-ghost btn-circle btn-sm">
            <BsThreeDotsVertical size={22} />
          </a>
        </DropdownMenu>
      </div>

      <button className="card card-side border-base-300 border-1 p-2 w-full text-left" onClick={() => onOpen?.(id)}>
        <div className="card-body pt-3 p-0 mx-4">
          <div className="flex items-center gap-4">
            <Image src={`https://customer-pg79d6x5qysbf96p.cloudflarestream.com/${id}/thumbnails/thumbnail.jpg?time=1s&width=160`} cdn={false} width='160px' className="w-20 h-20 rounded-md object-cover" height='160px' />
            <div className="flex w-full flex-col h-full">
              <h2 className="font-bold text-lg text-ellipsis overflow-hidden whitespace-nowrap">
                {title}
              </h2>
              <p className="text-grey font-light text-sm text-ellipsis overflow-hidden">
                {description}
              </p>
            </div>
          </div>
          <div className="divider my-0"></div>

          <div>
            <div className="flex justify-between">
              {/* genre... */}
            </div>
          </div>
        </div>
      </button>
    </div>
  )
})

export default VideoCard