import Modal from "@components/Modal"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import SelectSongs from "../fields/select-songs";
import { FormProvider, useForm } from "react-hook-form";
import Button from "@components/Button";
import Title from "../fields/title";
import ReleaseType from "../fields/release-type";
import Version from "../fields/version";
import CoverArt from "../fields/cover-art";
import Genres from "../fields/genres";
import Artists from "../fields/artists";
import Label from "../fields/label";
import Description from "../fields/description";
import Group from "./group";
import ReleaseDate from "../fields/release-date";
import LicenseType from "../fields/licence-type";
import CreativeWorks from "../fields/legal-owner.creative-works";
import Release from "../fields/legal-owner.release";
import RegionSelector from "../fields/region-selector";
import Budget from "../fields/budget";
import DistributionPlatform from "../fields/distribution-platform";
import { FormResult } from "./form";
import { useRelease } from "hooks/api/useRelease";
import { LicenceType, PriceCategory, ReleaseStatus, ValueTypes } from "zeus";
import ReferenceNumber from "../fields/reference-number";
import UPC from "../fields/upc";
import useStorage from "hooks/useStorage";
import { RoleOptionsList } from "constants/RolesMap";
import ReleaseTypeMap from "constants/ReleaseTypeMap";
import { primaryGenres } from "constants/Genre/primary";
import { secondaryGenres } from "constants/Genre/secondary";
import primaryLanguagesMap from "constants/primaryLanguageMap";
import { useState } from "react";

const EditRelease = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { editRelease } = useRelease();
  const { upload } = useStorage('image');
  const location = useLocation();
  const { id } = useParams() as { id: string }
  const release = location.state.release as ValueTypes['ReleaseOutput'];
  const songs = location.state.songs as ValueTypes['SongOutput'][];

  const form = useForm<FormResult>({
    mode: 'onChange', defaultValues: {
      artists: release.release_info?.artists?.map(artist => ({ name: artist.name, role: RoleOptionsList.find((role) => role.value === artist.role) })),
      version: release.release_info?.version as string,
      "cover-art": release.release_info?.cover_art as string | FileList,
      "creative-works": ({ title: release.licence?.legal_owner_of_work?.name, year: release.licence?.legal_owner_of_work?.year }),
      "digital-release-date": release.licence?.digital_release_date as string,
      "licence-type": release.licence?.licence_type as LicenceType,
      "non-publishing-regions": release.licence?.excluded_territories as unknown as string[],
      "release-date": release.licence?.original_release_date as string,
      "release-type": ReleaseTypeMap.find(releaseType => releaseType.value === release.release_info?.release_type as ValueTypes['ReleaseType']),
      description: release.release_info?.release_description as string,
      distributions: Object.values(release.distribution_platforms).filter((platform) => (release.distribution_platforms as string[] || []).includes(platform)).map((platform) => ({ [platform]: true })).reduce((a, b) => ({ ...a, ...b }), {}),
      identity: release.release_info?.identity,
      label: release.release_info?.label,
      language: primaryLanguagesMap.find(lang => lang.value === release.release_info?.title_language),
      primary_genre: primaryGenres.find(genre => genre.value === release.release_info?.primary_genre),
      release: ({ title: release.licence?.legal_owner_of_release?.name, year: release.licence?.legal_owner_of_release?.year }),
      secondary_genre: secondaryGenres.find(genre => genre.value === release.release_info?.secondary_genre),
      title: release.title as string,
      songs: songs?.map((song) => ({ label: song.title, value: song.id })),
      budget: release.licence?.price_category as PriceCategory,
    }
  })


  const onSubmit = async (data: FormResult) => {
    setIsLoading(true);
    try {

      const allDistribution = data.distributions?.all;
      const distributions = Object.keys(data.distributions).filter(d => data.distributions[d]);

      const isWorldPublishing = data["non-publishing-regions"].includes("World");
      const regions = data["non-publishing-regions"];
      let excluded_territories = isWorldPublishing ? regions.filter(r => r !== "World") : regions;

      let coverArtUrl: string = data['cover-art'] as string;

      if (data["cover-art"] instanceof FileList) {
        coverArtUrl = await upload(data["cover-art"][0]);
      }


      const response = await editRelease(id, {
        title: data.title,
        distribution_platforms: allDistribution ? ['all'] : distributions,
        songs: data.songs.map(song => ({ id: song.value })),
        licence: {
          digital_release_date: new Date(data["digital-release-date"]).toISOString(),
          legal_owner_of_work: {
            name: data["creative-works"].name,
            year: Number(data["creative-works"].year),
          },
          legal_owner_of_release: {
            name: data["release"].name,
            year: Number(data["release"].year),
          },
          licence_type: data["licence-type"] as LicenceType,
          original_release_date: new Date(data["release-date"]).toISOString(),
          price_category: data["budget"],
          excluded_territories: isWorldPublishing ? undefined : excluded_territories,
        },
        release_info: {
          artists: data.artists.map(artist => ({
            name: artist.name || '',
            role: artist.role?.value || '',
            ownership: 0,
          })),
          cover_art: coverArtUrl as string,
          release_description: data.description,
          label: data.label,
          primary_genre: data.primary_genre?.value,
          secondary_genre: data.secondary_genre?.value,
          title_language: data.language.value,
          release_type: data["release-type"].value,
          version: data.version,
          identity: {
            reference_number: data.identity.reference_number,
            request_ref_no: data.identity.request_ref_no,
            request_upc: data.identity.request_upc,
            upc: data.identity.upc
          },
        },
        status: release.status as ReleaseStatus
      }, songs?.map(song => ({ id: song.id })))

      if (response?.id) {
        navigate(`/releases/${response.id.slice(1, response.id.length)}`);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal className="max-w-5xl" id="new-release" visible onClose={() => navigate(-1)}>
      <div className="font-bold text-lg m-5 mb-0">
        Edit Release
      </div>

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>

          <Group title="General">
            <Title />
            <SelectSongs />
            <ReleaseType />
            <Genres />
            <Version />
            <CoverArt />
            <Artists />
            <Label />
            <Description />
            <ReferenceNumber />
            <UPC />
          </Group>

          <Group title="License & Distribution">
            <ReleaseDate />
            <LicenseType />
            <CreativeWorks />
            <Release />
            <RegionSelector />
            <Budget />
            <DistributionPlatform />
          </Group>

          <div className="flex justify-center gap-4 mb-4 px-4">
            <Button disabled={isLoading} className="flex-1" type='submit'>Submit</Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default EditRelease