const DistributionPlatforms = {
  "All": "all",
  "Amazon Music": "amazon-music",
  "Anghami": "anghami",
  "AWA": "awa",
  "Beatport": "beatport",
  "Boomplay": "boomplay",
  "Claro Música": "claro-musica",
  "Deezer": "deezer",
  "7 Digital": "7-digital",
  "Facebook Rights Manager": "facebook-rights-manager",
  "Gracenote": "gracenote",
  "iHeart": "iheart",
  "iTunes / Apple Music": "itunes-apple-music",
  "Jaxsta": "jaxsta",
  "JOOX": "joox",
  "Kanjian": "kanjian",
  "KDigital Media": "kdigital-media",
  "Kkbox": "kkbox",
  "Lickd": "lickd",
  "MixCloud": "mixcloud",
  "NetEase Cloud Music": "netease-cloud-music",
  "Pandora": "pandora",
  "Peloton": "peloton",
  "TikTok Music / Resso": "tiktok-music-resso",
  "Qobuz": "qobuz",
  "Rhapsody/Napster": "rhapsody-napster",
  "JioSaavn": "jiosaavn",
  "Soundcloud": "soundcloud",
  "Soundexchange": "soundexchange",
  "Spotify": "spotify",
  "TikTok": "tiktok",
  "Tencent": "tencent",
  "Tidal": "tidal",
  "YouTube Music": "youtube-music",
  "YouTube Audio Content ID": "youtube-audio-content-id",
};

export default DistributionPlatforms as Record<string, string>;