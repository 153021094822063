import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../hooks";

const AuthRoute = () => {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return <Outlet />;
};

export default AuthRoute;
