import { RadioGroup } from '@headlessui/react'
import { FC } from 'react'

export const Radio: FC<{ label?: string, value: string }> = ({ value, label }) => {
  return (
    <>
      <label className='flex gap-2'>
        <RadioGroup.Option value={value} className='radio' />
        {label}
      </label>
    </>
  )
}