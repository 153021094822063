import {
  PiInstagramLogo,
  PiTiktokLogo,
  PiTwitterLogo,
  PiYoutubeLogo,
} from "react-icons/pi";

import { Link } from "react-router-dom";

const navLinks = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "Offer",
    link: "#",
  },
  {
    title: "Contact",
    link: "https://zikitunez.com/contact",
  },
  {
    title: "Login",
    link: "/login",
  },
];

const socialLinks = [
  {
    id: "@zikitunez",
    url: "https://tiktok.com/@zikitunez",
    icon: PiTiktokLogo,
  },
  {
    id: "@zikitunesug",
    url: "https://youtube.com/@zikitunez",
    icon: PiYoutubeLogo,
  },
  {
    id: "@zikitunesug",
    url: "https://twitter.com/@zikitunesug",
    icon: PiTwitterLogo,
  },
  {
    id: "zikitunezug",
    url: "https://instagram.com/zikitunezug",
    icon: PiInstagramLogo,
  },
];

export const Landing = (props: {}) => {
  return (
    <div className="bg-[#4466e2]">
      <img
        className="absolute object-cover min-h-full w-full inset-0"
        src={
          "https://imagedelivery.net/X2Vymse95DWhWMx3YOWyog/9807a66d-d348-48e3-d1f1-958eeb328800/fullhd"
        }
      />
      <nav className="tabs font-semibold pt-6 sm:pl-10">
        {navLinks.map((link) => (
          <Link
            to={link.link}
            key={link.link}
            className="tab text-base-100 sm:text-lg"
          >
            {link.title}
          </Link>
        ))}
      </nav>
      <section className="text-base-100 m-4 sm:m-16">
        <header className="relative z-10 mt-14 md:mt-52">
          <h1 className="font-extrabold">
            <span className="text-md sm:text-2xl uppercase block">
              Beyond The Beatz
            </span>
            <span className="text-4xl sm:text-7xl whitespace-pre-line">
              Ziki Tunez{"\n"}Distribution
            </span>
          </h1>
          <p className="max-w-[460px] my-8">
            Let your music reach new heights through our global streaming 
            network, connecting you to over 250 digital platforms. 
            Join us and let your sound captivate audiences everywhere!
          </p>
          <Link to="/register" className="btn glass">
            Register Now
          </Link>
        </header>
      </section>
      <footer className="z-10 relative mt-14 mx-4 sm:mx-12">
        <ul className="text-base-100 flex flex-col md:flex-row gap-4">
          {socialLinks.map((link) => (
            <li className="">
              <a href={link.url} className="flex gap-2">
                {<link.icon size={30} />}
                {link.id}
              </a>
            </li>
          ))}
        </ul>
      </footer>
    </div>
  );
};
