export const secondaryGenres = [
  { label: "Afrobeat", value: "AFROBEAT" },
  { label: "Afropop", value: "AFROPOP" },
  { label: "Alternative", value: "ALTERNATIVE" },
  { label: "Big Band", value: "BIG BAND" },
  { label: "Blues", value: "BLUES" },
  { label: "Children's Music", value: "CHILDREN'S MUSIC" },
  { label: "Christian/Gospel", value: "CHRISTIAN/GOSPEL" },
  { label: "Classical", value: "CLASSICAL" },
  { label: "Comedy", value: "COMEDY" },
  { label: "Country", value: "COUNTRY" },
  { label: "Dance", value: "DANCE" },
  { label: "Electronic", value: "ELECTRONIC" },
  { label: "Fitness & Workout", value: "FITNESS & WORKOUT" },
  { label: "Folk", value: "FOLK" },
  { label: "French Pop", value: "FRENCH POP" },
  { label: "German Folk", value: "GERMAN FOLK" },
  { label: "German Pop", value: "GERMAN POP" },
  { label: "Hip Hop/Rap", value: "HIP HOP/RAP" },
  { label: "Holiday", value: "HOLIDAY" },
  { label: "J-Pop", value: "J-POP" },
  { label: "Jazz", value: "JAZZ" },
  { label: "K-Pop", value: "K-POP" },
  { label: "Latin", value: "LATIN" },
  { label: "Latin Urban", value: "LATIN URBAN" },
  { label: "Metal", value: "METAL" },
  { label: "New Age", value: "NEW AGE" },
  { label: "Pop", value: "POP" },
  { label: "Punk", value: "PUNK" },
  { label: "R&B/Soul", value: "R&B/SOUL" },
  { label: "Reggae", value: "REGGAE" },
  { label: "Rock", value: "ROCK" },
  { label: "Singer/Songwriter", value: "SINGER/SONGWRITER" },
  { label: "Soundtrack", value: "SOUNDTRACK" },
  { label: "Spoken Word", value: "SPOKEN WORD" },
  { label: "Vocal", value: "VOCAL" },
  { label: "World", value: "WORLD" },
  { label: "World/African", value: "WORLD/AFRICAN" },
  { label: "Soukous", value: "SOUKOUS" },
  { label: "Kwaito", value: "KWAITO" },
  { label: "Afro Hip hop music", value: "AFRO HIP HOP MUSIC" },
  { label: "Coupé-décalé", value: "COUPÉ-DÉCALÉ" },
  { label: "Gnawa music", value: "GNAWA MUSIC" },
  { label: "Palm-wine music", value: "PALM-WINE MUSIC" },
  { label: "Mbalax", value: "MBALAX" },
  { label: "Chimurenga music", value: "CHIMURENGA MUSIC" },
  { label: "Rhythm and blues", value: "RHYTHM AND BLUES" },
  { label: "Makossa", value: "MAKOSSA" },
  { label: "Amapiano", value: "AMAPIANO" },
  { label: "Benga music", value: "BENGA MUSIC" },
  { label: "Gqom", value: "GQOM" },
  { label: "Music of West Africa", value: "MUSIC OF WEST AFRICA" },
  { label: "Mbaqanga", value: "MBAQANGA" },
  { label: "Isicathamiya", value: "ISICATHAMIYA" },
  { label: "Folk music", value: "FOLK MUSIC" },
  { label: "Funaná", value: "FUNANÁ" },
  { label: "Bongo Flava", value: "BONGO FLAVA" },
  { label: "Morna", value: "MORNA" },
  { label: "Jùjú music", value: "JÙJÚ MUSIC" },
  { label: "Apala", value: "APALA" },
  { label: "Hiplife", value: "HIPLIFE" },
  { label: "Bantu music", value: "BANTU MUSIC" },
  { label: "Bikutsi", value: "BIKUTSI" },
  { label: "Semba", value: "SEMBA" },
  { label: "Taarab", value: "TAARAB" },
  { label: "Malipenga", value: "MALIPENGA" },
  { label: "Salegy", value: "SALEGY" },
  { label: "Zamrock", value: "ZAMROCK" },
  { label: "Ndombolo", value: "NDOMBOLO" },
  { label: "Kwassa kwassa", value: "KWASSA KWASSA" },
  { label: "Assiko", value: "ASSIKO" },
  { label: "Blues", value: "BLUES" },
  { label: "Baka music", value: "BAKA MUSIC" },
  { label: "Fuji music", value: "FUJI MUSIC" },
  { label: "Desert blues", value: "DESERT BLUES" },
  { label: "Kizomba", value: "KIZOMBA" },
  { label: "South African jazz", value: "SOUTH AFRICAN JAZZ" },
  { label: "Coladeira", value: "COLADEIRA" },
  { label: "African hip hop", value: "AFRICAN HIP HOP" },
  { label: "Soul music", value: "SOUL MUSIC" },
  { label: "Funk", value: "FUNK" },
  { label: "Lubandana", value: "LUBANDANA" },
  { label: "Backsimba", value: "BACKSIMBA" },
  { label: "Lakaraka", value: "LAKARAKA" },
  { label: "Kwivuga (Poetry)", value: "KWIVUGA (POETRY)" },
  { label: "Afro Soul", value: "AFRO SOUL" },
  { label: "Amakondere", value: "AMAKONDERE" },
  { label: "Ekitaguro", value: "EKITAGURO" },
  { label: "Kadodi", value: "KADODI" },
  { label: "Runyege", value: "RUNYEGE" },
  { label: "Entogoro", value: "ENTOGORO" },
  { label: "Lingala", value: "LINGALA" },
  { label: "Rumba", value: "RUMBA" },
  { label: "Zouk", value: "ZOUK" },
  { label: "African heavy metal", value: "AFRICAN HEAVY METAL" },
  { label: "African hip hop", value: "AFRICAN HIP HOP" },
  { label: "Afro house", value: "AFRO HOUSE" },
  { label: "Afrobeat", value: "AFROBEAT" },
  { label: "Afrobeats", value: "AFROBEATS" },
  { label: "Afro-soul", value: "AFRO-SOUL" },
  { label: "Amapiano", value: "AMAPIANO" },
  { label: "Apala", value: "APALA" },
  { label: "Azonto", value: "AZONTO" },
  { label: "Batuque", value: "BATUQUE" },
  { label: "Benga", value: "BENGA" },
  { label: "Bikutsi", value: "BIKUTSI" },
  { label: "Bongo Flava", value: "BONGO FLAVA" },
  { label: "Boomba (aka Kapuka)", value: "BOOMBA (AKA KAPUKA)" },
  { label: "Cape Jazz", value: "CAPE JAZZ" },
  { label: "Chimurenga", value: "CHIMURENGA" },
  { label: "Coladeira", value: "COLADEIRA" },
  { label: "Coupé-Décalé", value: "COUPÉ-DÉCALÉ" },
  { label: "Fuji music", value: "FUJI MUSIC" },
  { label: "Funaná", value: "FUNANÁ" },
  { label: "Genge", value: "GENGE" },
  { label: "Gengeton", value: "GENGETON" },
  { label: "Gqom", value: "GQOM" },
  { label: "Highlife", value: "HIGHLIFE" },
  { label: "Hipco", value: "HIPCO" },
  { label: "Hiplife", value: "HIPLIFE" },
  { label: "Igbo highlife", value: "IGBO HIGHLIFE" },
  { label: "Isicathamiya", value: "ISICATHAMIYA" },
  { label: "Jit", value: "JIT" },
  { label: "Jùjú", value: "JÙJÚ" },
  { label: "Kalindula", value: "KALINDULA" },
  { label: "Kilalaky", value: "KILALAKY" },
  { label: "Kizomba", value: "KIZOMBA" },
  { label: "Kuduro", value: "KUDURO" },
  { label: "Kwaito", value: "KWAITO" },
  { label: "Kwela", value: "KWELA" },
  { label: "Makossa", value: "MAKOSSA" },
  { label: "Maloya", value: "MALOYA" },
  { label: "Marrabenta", value: "MARRABENTA" },
  { label: "Mbalax", value: "MBALAX" },
  { label: "Mbaqanga", value: "MBAQANGA" },
  { label: "Mbube", value: "MBUBE" },
  { label: "Morna", value: "MORNA" },
  { label: "Museve", value: "MUSEVE" },
  { label: "Pandza", value: "PANDZA" },
  { label: "Sudanese popular music", value: "SUDANESE POPULAR MUSIC" },
  { label: "Ndombolo", value: "NDOMBOLO" },
  { label: "Ogene", value: "OGENE" },
  { label: "Palm-wine (aka Maringa)", value: "PALM-WINE (AKA MARINGA)" },
  { label: "Raï", value: "RAÏ" },
  { label: "Sakara", value: "SAKARA" },
  { label: "Salegy", value: "SALEGY" },
  { label: "Sega", value: "SEGA" },
  { label: "Semba", value: "SEMBA" },
  { label: "Shangaan electro", value: "SHANGAAN ELECTRO" },
  { label: "Soukous", value: "SOUKOUS" },
  { label: "Street pop", value: "STREET POP" },
  { label: "Sungura", value: "SUNGURA" },
  { label: "Taarab", value: "TAARAB" },
  { label: "Zougloo", value: "ZOUGLOO" },
  { label: "Amatali", value: "AMATALI" }
];