import Button from "@components/Button";
import Image from "@components/Image";
import Logo from "@images/ZikiTunesLogo.svg";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { LocationState } from "types/locationState";
import BaseInput from "@components/Inputs/BaseInput";
import useAuth from "hooks/useAuth";
import useOTPVerification from "hooks/useOTPVerification";
import { Auth } from "aws-amplify";

const OTPVerification = () => {
  const location = useLocation();
  const { sendOTPStatus, verifyOTPStatus, sendOTP, verifyOTP } =
    useOTPVerification();

  const { isLoading, error, signIn } = useAuth();
  const initialStatus = {
    error: false,
    success: false,
    message: "",
  };

  const [status, setStatus] = useState(initialStatus);

  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [counter, setCounter] = useState(59); // 60 Seconds Counter
  const [shouldLogin, setShouldLogin] = useState(true); // 60 Seconds Counter
  const locationState = location.state as LocationState;

  useEffect(() => {
    if (!locationState?.email) {
      // return navigate("/");
    }
    if (!locationState?.username || !locationState?.password) {
      // sendOTP(locationState.email);
      setShouldLogin(false);
    }
    // console.log(locationState);
  }, [location]);

  useEffect(() => {
    if (sendOTPStatus.error || verifyOTPStatus.error || error) {
      setStatus({
        success: false,
        error: true,
        message: sendOTPStatus?.error || verifyOTPStatus?.error || error,
      });
    }
  }, [sendOTPStatus.error, verifyOTPStatus.error, error]);

  const login = async () => {
    const { email, password } = locationState;
    try {
      await signIn(email, password);
      navigate("/");
    } catch (err: any) {
      console.log(err);
      setStatus({
        error: true,
        success: false,
        message: err.message as string,
      });
    }
  };

  useEffect(() => {
    if (verifyOTPStatus.isSuccess) {
      if (shouldLogin) {
        login();
        setStatus(initialStatus);
      } else {
        setStatus({
          error: false,
          success: true,
          message: "Account verified successfully! Redirecting you to login.",
        });
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      }
    }

    sendOTPStatus.isSuccess &&
      setStatus({
        error: false,
        success: true,
        message: "OTP has been sent successfully!",
      });
  }, [verifyOTPStatus.isSuccess, sendOTPStatus.isSuccess]);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer as number);
  }, [counter]);

  const resendOTP = async () => {
    await sendOTP(locationState.username);
    setCounter(59);
  };

  const onSubmit = async (formData: any) => {
    const otp = formData.otp;
    if (shouldLogin) {
      await verifyOTP(locationState.username, otp);
    } else {
      await verifyOTP(locationState.email, otp);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <form
        className="form flex flex-col gap-2 w-80 items-center"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Image src={Logo} height="100px" width="100px" />
        <h3 className="text-2xl mb-1">Verify OTP</h3>
        <p className="text-sm text-center">
          Please enter OTP sent to {locationState?.phone ?? "your phone"}
        </p>
        <a href="#" className="text-sm text-center mb-2">
          Wrong number?
        </a>
        {status.message && (
          <div className="w-full">
            <p
              className={`text-sm text-center ${
                status.success ? "text-success" : "text-error"
              }`}
            >
              {status.message}
            </p>
          </div>
        )}

        <BaseInput
          type="text"
          autoComplete="one-time-code"
          inputMode="numeric"
          {...register("otp", { required: true })}
          placeholder="OTP"
        />
        <Button className="w-full">
          {sendOTPStatus.isLoading || verifyOTPStatus.isLoading || isLoading
            ? "Loading..."
            : "Submit"}
        </Button>
        {counter !== 0 ? (
          <p className="text-sm text-center mt-2">
            Resend OTP in <span className="font-semibold">00:{counter}</span>
          </p>
        ) : (
          <Button className="w-full" onClick={resendOTP}>
            Resend OTP
          </Button>
        )}
      </form>
    </div>
  );
};

export default OTPVerification;
