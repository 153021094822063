const {
  ENV_IDENTITY_POOL_ID,
  ENV_REGION,
  ENV_USER_POOL_ID,
  ENV_USER_POOL_WEB_CLIENT_ID,
} = import.meta.env;

export default {
  userPoolId: ENV_USER_POOL_ID,
  region: ENV_REGION,
  userPoolWebClientId: ENV_USER_POOL_WEB_CLIENT_ID,
  identityPoolId: ENV_IDENTITY_POOL_ID,
};
