import Tooltip from "@components/Tooltip";
import { forwardRef } from "react";
import { UseFormRegister } from "react-hook-form";
import { InputProps } from "types/Input";
import { twMerge } from 'tailwind-merge'
const BaseInput = forwardRef<
  HTMLInputElement,
  InputProps & Omit<ReturnType<UseFormRegister<any>>, 'onChange' | 'onBlur'>
>(({ label, labelAlt, className, errorText, labelClassName, adornmentClassName, tooltip, adornment, adornmentPosition = 'right', ...props }, ref) => {
  return (
    <div className="form-control w-full">
      {label || labelAlt ? (
        <label className={`label ${labelClassName ?? ""}`}>
          <span className="label-text items-center flex">
            {label}{" "}
            {tooltip ? <Tooltip text={tooltip} className="ml-2" /> : null}
          </span>
          <span className="label-text-alt">{labelAlt}</span>
        </label>
      ) : null}
      <div className="w-full relative">
        <input
          type="text"
          className={`input input-bordered w-full ${className ?? ""}`}
          {...props}
          ref={ref}
        />
        <div className={twMerge(`absolute`, adornmentPosition === 'right' ? 'right-[5px] top-[5px]' : 'left-3 top-3', adornmentClassName)}>
          {adornment}
        </div>
      </div>
      {errorText ? (
        <label>
          <span className="text-xs text-error">
            <i className="fas fa-exclamation-triangle"></i> {errorText}
          </span>
        </label>
      ) : null}
    </div>
  );
});

export default BaseInput;
