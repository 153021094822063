import { HTMLAttributes } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

interface Props extends HTMLAttributes<HTMLDivElement> {
  text: string;
}

const Tooltip = ({ text, ...props }: Props) => {
  return (
    <div className="tooltip" data-tip={text} {...props}>
      <AiOutlineQuestionCircle />
    </div>
  );
};

export default Tooltip;
