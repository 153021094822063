import { RegionSelector as BaseRegionSelector } from "@components/RegionSelector"
import { useFormContext } from "react-hook-form";
const RegionSelector = () => {
  const { control } = useFormContext();

  return (
    <div>
      <label className="label font-semibold">
        <span className="label-text">Publishing Regions</span>
      </label>
      <BaseRegionSelector control={control} name="non-publishing-regions" />
    </div>
  )
}

export default RegionSelector