import Modal from "@components/Modal"
import { useNavigate } from "react-router-dom";
import UploadView from "./UploadView";
import { useState } from "react";
import AddMetadataView from "./AddMetadataView";

const VideoUpload = () => {
  const navigate = useNavigate();
  const [uploadProgress, setUploadProgress] = useState<number | null>(null)
  const [video, setVideo] = useState<File | undefined>(undefined);
  const [videoId, setVideoId] = useState<string>('');
  const handleOnClose = () => {
    navigate(-1);
  };


  const getStatus = () => {
    if (!uploadProgress && video && !videoId) {
      return 'processing'
    }
    if (uploadProgress) {
      return 'uploading'
    }
    return ''
  }

  return (
    <Modal visible id="videoModal" className="w-11/12 max-w-6xl" status={getStatus()} processing={uploadProgress !== null} progressValue={uploadProgress ?? 0} onClose={handleOnClose}>
      {!video || !videoId ?
        <UploadView onProgress={setUploadProgress} onSuccess={(streamId) => setVideoId(streamId ?? '')} onFile={(file) => setVideo(file)} /> : <AddMetadataView videoId={videoId} video={video} />}
    </Modal >
  )
}

export default VideoUpload