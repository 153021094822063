import BaseInput from "@components/Inputs/BaseInput"
import { useFormContext } from "react-hook-form";


const Version = () => {
  const { register } = useFormContext();
  return (
    <BaseInput placeholder="Remix, Radio Edit, etc..." label="Version" labelClassName="font-semibold pt-0" {...register('version')} />
  )
}

export default Version