import { Radio, RadioGroup } from "@components/Radio"
import { useFormContext } from "react-hook-form"
import { PriceCategory } from "zeus"

const Budget = () => {
  const { control } = useFormContext()
  return (
    <div>
      <label className="label font-semibold pt-0">
        <span className="label-text">Budget</span>
      </label>
      <RadioGroup name="budget" defaultValue={PriceCategory.BUDGET} className="w-full my-4 ml-2" control={control} radioRootClassName="flex-row gap-14">
        <Radio value={PriceCategory.BUDGET} label="Budget" />
        <Radio value={PriceCategory.MID} label="Mid" />
        <Radio value={PriceCategory.FULL} label="Full" />
        <Radio value={PriceCategory.PREMIUM} label="Premium" />
      </RadioGroup>
    </div>
  )
}

export default Budget