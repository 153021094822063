import BaseInput from "@components/Inputs/BaseInput"
import Checkbox from "@components/Inputs/Checkbox"
import { Controller, useFormContext } from "react-hook-form"

const ReferenceNumber = () => {
  const { control, watch } = useFormContext();
  const hasRefNo = watch('identity.reference_number', '');
  const createNewRefNo = watch('identity.request_ref_no', false);

  return (
    <div>
      <Controller control={control} name="identity.reference_number" render={({ field, fieldState: { error } }) => (
        <>
          <BaseInput
            placeholder="Reference No."
            label="Reference No."
            disabled={createNewRefNo}
            adornment={
              <Checkbox name="identity.request_ref_no" label="Issue new" disabled={hasRefNo} control={control} />
            }
            adornmentClassName="-top-10"
            labelClassName="font-semibold"
            {...field}
          />
          {error?.message ? <p className="text-error font-semibold pt-1">{error.message}</p> : null}
        </>
      )} />

    </div>
  )
}

export default ReferenceNumber