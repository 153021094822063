import Tooltip from "@components/Tooltip";
import React, { ReactNode, forwardRef } from "react";
import { UseFormRegister } from "react-hook-form";
import { TextAreaProps } from "types/Input";

const TextArea = forwardRef<
  HTMLTextAreaElement,
  TextAreaProps & Omit<ReturnType<UseFormRegister<any>>, 'onChange' | 'onBlur'> & { adornment?: ReactNode }
>((props, ref) => {
  const { label, labelAlt, className, errorText, adornment = null, ...rest1 } = props;
  const { labelClassName, tooltip, ...rest } = rest1;

  return (
    <div className="form-control w-full">
      <div className="flex justify-between">
        {label || labelAlt ? (
          <label className={`label ${labelClassName ?? ""}`}>
            <span className="label-text items-center flex">
              {label}{" "}
              {tooltip ? <Tooltip text={tooltip} className="ml-2" /> : null}
            </span>
            <span className="label-text-alt">{labelAlt}</span>
          </label>
        ) : null}
        {adornment}
      </div>

      <textarea
        className={`textarea textarea-bordered ${className ?? ""}`}
        {...rest}
        ref={ref}
      ></textarea>
      {errorText ? (
        <label>
          <span className="text-sm text-error">{errorText}</span>
        </label>
      ) : null}
    </div>
  );
});

export default TextArea;
