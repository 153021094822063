import { useCallback, useEffect } from "react";
import { ModalProps } from "types/modal";

const Modal = ({
  children,
  id,
  onClose,
  className = "",
  visible = false,
  closable = true,
  footer = false,
  maskClosable = false,
  progressValue = 0,
  status = "",
}: ModalProps) => {
  const onKeyDownClose = useCallback((event: KeyboardEvent) => {
    event.key?.toLowerCase() === "escape" && onClose?.()
  }, []);

  useEffect(() => {
    if (visible) {
      document.addEventListener("keydown", onKeyDownClose);
    } else {
      document.removeEventListener("keydown", onKeyDownClose);
    }
    return () => {
      document.removeEventListener("keydown", onKeyDownClose);
    };
  }, [visible]);

  if (maskClosable) {
    return (
      <>
        <input
          type="checkbox"
          id={id}
          checked={visible}
          defaultChecked={visible}
          className="modal-toggle"
        />
        <label htmlFor={id} className="z-50 modal cursor-pointer">
          <label className={`modal-box relative ${className}`}>
            {closable && (
              <label
                htmlFor={id}
                onClick={onClose}
                className="btn btn-sm btn-circle fixed right-2 top-2"
              >
                ✕
              </label>
            )}
            {children}
            {footer && (
              <div className="modal-action">
                <label htmlFor={id} className="btn">
                  Ok
                </label>
              </div>
            )}

            {status === "uploading" && (
              <>
                <p
                  className="text-sm text-primary-focus mt-5"
                  style={{
                    marginLeft: "-0.5rem",
                    marginBottom: "-2rem",
                  }}
                >
                  Uploading...({parseInt(progressValue.toString())}%)
                </p>
                <progress
                  className="progress progress-primary w-100"
                  style={{
                    margin: "-1.5rem",
                    marginBottom: "-2rem",
                    width: "31.5rem",
                  }}
                  value={progressValue}
                  max="100"
                ></progress>
              </>
            )}

            {status === "processing" && (
              <>
                <p
                  className="text-sm text-primary-focus mt-5"
                  style={{
                    marginLeft: "-0.5rem",
                    marginBottom: "-2rem",
                  }}
                >
                  Processing...
                </p>
                <div className="progress-bar">
                  <div className="progress-bar-value"></div>
                </div>
              </>
            )}
          </label>
        </label>
      </>
    );
  }
  return (
    <>
      <input
        type="checkbox"
        id={id}
        defaultChecked={visible}
        checked={visible}
        className="modal-toggle"
      />
      <div className="modal z-50 flex flex-col">
        <div className={`modal-box relative ${className} p-0 scroll-w-0`}>
          <div className="modal-area">
            {closable && (
              <label
                htmlFor={id}
                onClick={onClose}
                className="btn btn-sm btn-circle fixed right-2 top-2"
              >
                ✕
              </label>
            )}
            {children}
            {footer && (
              <div className="modal-action">
                <label htmlFor={id} className="btn">
                  Ok
                </label>
              </div>
            )}
          </div>
          <div className="status-bar">
            {status === "uploading" && (
              <>
                <p className="text-sm pl-3">
                  Uploading...({parseInt(progressValue.toString())}%)
                </p>
                <progress
                  className="progress progress-primary w-100 -mb-1.5"
                  value={progressValue}
                  max="100"
                ></progress>
              </>
            )}
            {status === "processing" && (
              <>
                <div className="progress-bar">
                  <div className="progress-bar-value"></div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Modal;
