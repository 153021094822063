import { MdAddCircleOutline } from "react-icons/md"
import { Link } from "react-router-dom"
import VideosList from "./VideosList"

const VideosPage = () => {

  return (
    <div className="m-7 flex flex-col">
      <div className="flex w-full justify-end">
        <Link to='/videos/upload'>
          <button className="btn btn-ghost flex gap-2">
            <MdAddCircleOutline size={22} className="inline-block" />
            New Video
          </button>
        </Link>
      </div>
      <VideosList />
    </div>
  )
}

export default VideosPage