import { useAppSelector } from "hooks";
import { Navigate, Outlet } from "react-router-dom";

const Private = () => {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  console.log("[PrivateRoute] isAuthenticated", isAuthenticated);

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default Private;
