import Image from "@components/Image"
import { BsImage } from "react-icons/bs"

const Preview = ({ image }: { image?: string }) => {
  return (
    <div>
      {image ? <Image src={image} cdn={!image.startsWith('blob')} className="w-48 h-48 object-cover rounded-md" /> : (
        <div className="w-48 h-48 bg-base-300 rounded-md flex items-center justify-center">
          <div className="flex flex-col items-center">
            <BsImage />
            <p className="text-sm text-base-content mt-1 select-none">Select File</p>
          </div>
        </div>
      )}
    </div>
  )
}

export default Preview