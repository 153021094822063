import Checkbox from "@components/Inputs/Checkbox"
import DistributionPlatforms from "constants/DistributionPlatforms"
import { Controller, useFormContext } from "react-hook-form";

const DistributionPlatform = () => {
  const { control, watch } = useFormContext();
  const isAllSelected = watch('distributions.all');

  return (
    <div>
      <label className="label font-semibold pt-0">
        <span className="label-text">Distribution Platform</span>
      </label>

      <div className="grid grid-cols-4 gap-2">
        {Object.keys(DistributionPlatforms).map((platform) => (
          <Controller control={control} key={platform} name={`distributions.${DistributionPlatforms[platform]}`} render={({ field }) => (
            <Checkbox label={platform} control={control} {...field} disabled={isAllSelected && field.name !== 'distributions.all'} />
          )} />
        ))}
      </div>
    </div>
  )
}

export default DistributionPlatform