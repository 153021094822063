import Button from "@components/Button";
import Image from "@components/Image";
import Logo from "@images/ZikiTunesLogo.svg";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import BaseInput from "@components/Inputs/BaseInput";
import { useLocation, useNavigate } from "react-router-dom";

export interface LocationProps {
  username: string;
}

const ForgotPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isPasswordReset, setIsPasswordReset] =
    useState<{ status: "error" | "success"; message: string }>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const locationState = location.state as LocationProps;

  useEffect(() => {
    if (!locationState?.username) {
      return navigate("/");
    }
    console.log(locationState);
  }, [location]);

  const [counter, setCounter] = useState(59); // 60 Seconds Counter

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer as number);
  }, [counter]);

  const onSubmit = async (formData: any) => {
    const { otp, password } = formData;
    try {
      const res = await Auth.forgotPasswordSubmit(
        locationState.username,
        otp,
        password
      );
      if (res) {
        setIsPasswordReset({
          status: "success",
          message:
            "Password has been reset successfully! You can now login with your new password.",
        });
      }
    } catch (error: any) {
      setIsPasswordReset({ status: "error", message: error.message });
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <form
        className="form flex flex-col gap-2 w-80 items-center"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Image src={Logo} height="100px" width="100px" />
        <h3 className="text-2xl mb-1">Reset Password</h3>
        <p className="text-sm text-center mb-4">
          Please enter the otp sent to {locationState.username}
        </p>
        <BaseInput
          type="number"
          {...register("otp", { required: true })}
          placeholder="OTP"
          errorText={errors.otp && errors.otp.message}
        />
        <BaseInput
          type="password"
          {...register("password", { required: true })}
          placeholder="New Passsword"
          errorText={errors.password && errors.password.message}
        />
        <Button className="w-full">Submit</Button>
        {isPasswordReset ? (
          <div className="w-full">
            <p
              className={`${isPasswordReset.status === "success"
                  ? "text-success"
                  : "text-error"
                } text-sm`}
            >
              {isPasswordReset.message}
            </p>
          </div>
        ) : null}
        {isPasswordReset?.status !== "success" && counter !== 0 && (
          <p className="text-sm text-center mt-2">
            Resend OTP in <span className="font-semibold">00:{counter}</span>
          </p>
        )}
        {isPasswordReset?.status !== "success" && counter == 0 && (
          <Button className="w-full" onClick={onSubmit}>
            Resend OTP
          </Button>
        )}
      </form>
    </div>
  );
};

export default ForgotPassword;
