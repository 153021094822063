import { RadioGroup as HeadlessRadioGroup, RadioGroupProps as HeadlessRadioGroupProps } from '@headlessui/react'
import { FC, ReactNode } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

type RadioGroupProps = Omit<HeadlessRadioGroupProps<any, string>, 'children'> & Omit<ControllerProps, 'render'> & {
  label?: string;
  radioRootClassName?: string;
  children: ReactNode | ReactNode[];
}

export const RadioGroup: FC<RadioGroupProps> = ({ children, className, label, radioRootClassName, ...props }) => {

  if (!props.name || !props.control) return null;
  return (
    <Controller
      defaultValue=""
      {...props}
      render={({ field }) => (
        <HeadlessRadioGroup className={twMerge("w-max", className)} name={field.name} value={field.value} onChange={field.onChange}>
          {label ? <HeadlessRadioGroup.Label className="label-text">{label}</HeadlessRadioGroup.Label> : null}

          <div className={twMerge('flex flex-col gap-2', label && 'mt-3', radioRootClassName)}>
            {children}
          </div>
        </HeadlessRadioGroup>
      )}
    />
  )
}