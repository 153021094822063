const primaryLanguagesMap = [
  { label: 'Afrikaans', value: 'AF' },
  { label: 'Arabic', value: 'AR' },
  { label: 'Assamese', value: 'AS' },
  { label: 'Bengali', value: 'BN' },
  { label: 'Bhojpuri', value: 'BH' },
  { label: 'Bulgarian', value: 'BG' },
  { label: 'Cantonese', value: 'CN' },
  { label: 'Catalan', value: 'CA' },
  { label: 'Chinese', value: 'ZH' },
  { label: 'Croatian', value: 'HR' },
  { label: 'Czech', value: 'CS' },
  { label: 'Danish', value: 'DA' },
  { label: 'Dutch', value: 'NL' },
  { label: 'English', value: 'EN' },
  { label: 'Estonian', value: 'ET' },
  { label: 'Finnish', value: 'FI' },
  { label: 'French', value: 'FR' },
  { label: 'German', value: 'DE' },
  { label: 'Greek', value: 'EL' },
  { label: 'Gujarati', value: 'GU' },
  { label: 'Haitian Creole', value: 'HT' },
  { label: 'Haryanvi', value: 'HA' },
  { label: 'Hebrew', value: 'HE' },
  { label: 'Hindi', value: 'HI' },
  { label: 'Hungarian', value: 'HU' },
  { label: 'Icelandic', value: 'IS' },
  { label: 'Indonesian', value: 'ID' },
  { label: 'Irish', value: 'GA' },
  { label: 'Italian', value: 'IT' },
  { label: 'Japanese', value: 'JA' },
  { label: 'Kannada', value: 'KN' },
  { label: 'Kazakh', value: 'KK' },
  { label: 'Korean', value: 'KO' },
  { label: 'Lao', value: 'LO' },
  { label: 'Latin', value: 'LA' },
  { label: 'Latvian', value: 'LV' },
  { label: 'Lingala', value: 'LN' },
  { label: 'Lithuanian', value: 'LT' },
  { label: 'Malay', value: 'MS' },
  { label: 'Norwegian', value: 'NO' },
  { label: 'Persian', value: 'FA' },
  { label: 'Polish', value: 'PL' },
  { label: 'Portuguese', value: 'PT' },
  { label: 'Punjabi', value: 'PA' },
  { label: 'Rajasthani', value: 'RA' },
  { label: 'Romanian', value: 'RO' },
  { label: 'Russian', value: 'RU' },
  { label: 'Sanskrit', value: 'SA' },
  { label: 'Sepedi', value: 'NS' },
  { label: 'Slovak', value: 'SK' },
  { label: 'Slovenian', value: 'SL' },
  { label: 'Spanish', value: 'ES' },
  { label: 'Swahili', value: 'SW' },
  { label: 'Swedish', value: 'SV' },
  { label: 'Tagalog', value: 'TL' },
  { label: 'Tamil', value: 'TA' },
  { label: 'Telugu', value: 'TE' },
  { label: 'Thai', value: 'TH' },
  { label: 'Turkish', value: 'TR' },
  { label: 'Ukrainian', value: 'UK' },
  { label: 'Urdu', value: 'UR' },
  { label: 'Vietnamese', value: 'VI' },
  { label: 'Zulu', value: 'ZU' },
];

export default primaryLanguagesMap