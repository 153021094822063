import { InputHTMLAttributes, forwardRef } from "react"

type DateInputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string
  labelClassName?: string
  future?: boolean
  defaultToday?: boolean
}

const DateInput = forwardRef<HTMLInputElement, DateInputProps>(({ label, labelClassName, future = true, defaultToday = true, ...props }: DateInputProps, ref) => {
  return (
    <div className="w-full">
      {label ? (
        <label className={`label ${labelClassName ?? ""}`}>
          <span className="label-text">{label}</span>
        </label>
      ) : null}
      <input ref={ref} type="date" max={!future ? new Date().toISOString().split('T')[0] : undefined} className='input w-full input-bordered' {...props} />
    </div>
  )
})

export default DateInput