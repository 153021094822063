import { FC, ReactNode, useCallback } from "react"
import { DropzoneState, useDropzone } from "react-dropzone";

type Props = {
  children: ({ getRootProps, getInputProps, dropping }: { getRootProps: DropzoneState['getRootProps'], getInputProps: DropzoneState['getInputProps'], dropping: boolean }) => ReactNode
  onFileDrop?: (file: File) => void
}

const DropVideo: FC<Props> = ({ children, onFileDrop }) => {

  const onDrop = useCallback((acceptedFiles: File[]) => {
    onFileDrop?.(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  return (
    children({ getRootProps, getInputProps, dropping: isDragActive })
  )
}

export default DropVideo