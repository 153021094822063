import Button from "@components/Button";
import Image from "@components/Image";
import BaseInput from "@components/Inputs/BaseInput";
import PasswordInput from "@components/Inputs/Password";
import Loader from "@components/Loader";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { LoginProps } from "types/form";
import useAuth from "hooks/useAuth";

const Login = () => {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm<LoginProps>();
  const { isLoading, error, signIn } = useAuth();

  const onSubmit = handleSubmit(async (formData) => {
    const { username, password } = formData;
    try {
      await signIn(username, password);
      navigate("/dashboard");
    } catch (error: any) {
      console.log(error);
      if (error.message === "User is not confirmed.") {
        navigate("/verify", {
          state: {
            email: username,
          },
        });
      }
    }
  });

  return isLoading ? (
    <Loader />
  ) : (
    <div className="flex justify-center items-center h-screen">
      <form
        className="form flex flex-col gap-2 w-80 items-center"
        onSubmit={onSubmit}
      >
        <Image src="ziki-logo-svg" height="100px" width="100px" />
        <h3 className="text-2xl mb-4">Login</h3>
        <div className="min-h-6 w-full">
          {error && <p className="text-error text-sm">{error}</p>}
        </div>
        <BaseInput
          label="Email"
          placeholder="Email"
          type="email"
          {...register("username", { required: true })}
        />
        <PasswordInput
          label="Password"
          placeholder="Password"
          type="password"
          {...register("password", { required: true })}
        />
        <div className="w-full my-2">
          <p className="text-sm">
            <Link to="/forgot-password" className="underline">
              Forgot Password?
            </Link>
          </p>
        </div>

        <Button className="w-full">Login</Button>
        <div className="w-full my-4">
          <p className="text-sm">
            Don't have an account?
            <Link to="/register" className="underline ml-2">
              Register here!
            </Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default Login;
