import { ButtonProps } from './Button';

const Button = ({ children, className, ...props }: ButtonProps) => {
  return (
    <button className={`btn disabled:opacity-40 disabled:cursor-not-allowed ${className || ''}`} {...props}>
      {children}
    </button>
  );
};

export default Button;
