import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { lazy } from "react";

import Login from "@pages/Authentication/Login";
import Register from "@pages/Authentication/Register";
import OTPVerification from "@pages/Authentication/OTPVerification";
import ForgotPassword from "@pages/Authentication/ForgotPassword";
import ResetPassword from "@pages/Authentication/ResetPassword";
import Private from "./PrivateRoute";
import AuthRoute from "./AuthRoute";
import Suspensed from "./Suspensed";
import { Landing } from "@pages/Landing";
import NewRelease from "@pages/Releases/NewRelease";
import Release from "@pages/Releases/Release";
import EditRelease from "@pages/Releases/EditRelease";
import VideosPage from "@pages/Videos";
import VideoUpload from "@pages/Videos/Upload";
const Home = lazy(() => import("@pages/Home"));
const UploadModal = lazy(() => import("@pages/Upload"));
const TermsUpload = lazy(() => import("@pages/Terms/TermsUpload"));
const Layout = lazy(() => import("@pages/Layout"));
const Profile = lazy(() => import("@pages/Profile"));
const ProfileSettings = lazy(() => import("@pages/Profile/ProfileSettings"));
const Preferences = lazy(() => import("@pages/Profile/Preferences"));
const AccountSettings = lazy(() => import("@pages/Profile/AccountSettings"));
const ReleaseFormModal = lazy(() => import("@pages/Releases/ReleaseFormModal"));
const UploadForm = lazy(() => import("@pages/Upload/UploadForm"));
const Albums = lazy(() => import("@pages/Releases"));
const Songs = lazy(() => import("@pages/Songs"));
const Stats = lazy(() => import("@pages/Stats"));
const Settings = lazy(() => import("@pages/Settings"));
const OTPForm = lazy(
  () => import("@pages/Authentication/OTPVerification/OTPForm"),
);

const AppRoutes = () => {
  let location = useLocation();
  let state = location.state as { backgroundLocation?: Location };
  return (
    <>
      <Routes location={state?.backgroundLocation || location}>
        <Route path="/" element={<Landing />} />
        <Route
          path="/profile"
          element={<Navigate to="/profile/profile-settings" replace />}
        />
        <Route
          path="/.well-known/change-password"
          element={<Navigate to="/profile/account-settings" replace />}
        />
        <Route element={<AuthRoute />}>
          <Route path="/login" element={<Suspensed component={<Login />} />} />
          <Route
            path="/register"
            element={<Suspensed component={<Register />} />}
          />
          <Route
            path="/forgot-password"
            element={<Suspensed component={<ForgotPassword />} />}
          />
          <Route
            path="/reset-password"
            element={<Suspensed component={<ResetPassword />} />}
          />
          <Route
            path="/verify"
            element={<Suspensed component={<OTPVerification />} />}
          />
          <Route
            path="/verify-account"
            element={<Suspensed component={<OTPForm />} />}
          />
          <Route
            path="/tnc"
            element={<Suspensed component={<TermsUpload />} />}
          />
        </Route>
        <Route element={<Private />}>
          <Route path="/" element={<Suspensed component={<Layout />} />}>
            <Route
              path="/dashboard"
              element={<Suspensed component={<Home />} />}
            />
            <Route
              path="/stats"
              element={<Suspensed component={<Stats />} />}
            />
            <Route
              path="/settings"
              element={<Suspensed component={<Settings />} />}
            />

            {/* songs upload */}
            <Route
              path="/upload"
              element={<Suspensed component={<UploadModal />} />}
            />
            <Route
              path="/upload/details"
              element={<Suspensed component={<UploadForm />} />}
            >
              <Route
                path="/upload/details/albums/new"
                element={<Suspensed component={<ReleaseFormModal />} />}
              />
            </Route>

            {/* releases */}
            <Route
              path="/releases/:id"
              element={<Suspensed component={<Release />} />}
            >
              <Route
                path="add"
                element={<Suspensed component={<ReleaseFormModal />} />}
              />
            </Route>
            <Route
              path="/releases"
              element={<Suspensed component={<Albums />} />}
            />

            {/* songs */}
            <Route path="/songs" element={<Suspensed component={<Songs />} />}>
              <Route
                path="/songs/:id"
                element={<Suspensed component={<UploadForm />} />}
              >
                <Route
                  path="/songs/:id/albums/new"
                  element={<Suspensed component={<ReleaseFormModal />} />}
                />
              </Route>
            </Route>

            {/* videos */}

            <Route path="/videos" element={<Suspensed component={<VideosPage />} />}>
              <Route
                path="/videos/:id"
                element={<Suspensed component={<UploadForm />} />}
              >
                <Route
                  path="/videos/:id/albums/new"
                  element={<Suspensed component={<ReleaseFormModal />} />}
                />
              </Route>
            </Route>

            {/* upload video */}

            <Route
              path="/videos/upload"
              element={<Suspensed component={<VideoUpload />} />}
            />
            <Route
              path="/videos/upload/details"
              element={<Suspensed component={<UploadForm />} />}
            >
              <Route
                path="/videos/upload/details/albums/new"
                element={<Suspensed component={<ReleaseFormModal />} />}
              />
            </Route>


            {/* profile */}
            <Route
              path="/profile"
              element={<Suspensed component={<Profile />} />}
            >
              <Route
                path="/profile/profile-settings"
                element={<Suspensed component={<ProfileSettings />} />}
              />
              <Route
                path="/profile/preferences"
                element={<Suspensed component={<Preferences />} />}
              />
              <Route
                path="/profile/account-settings"
                element={<Suspensed component={<AccountSettings />} />}
              />
            </Route>
          </Route>
        </Route>
      </Routes>
      {state?.backgroundLocation ? (
        <Routes>
          <Route
            path="/songs/:id"
            element={<Suspensed component={<UploadForm />} />}
          >
            <Route
              path="/songs/:id/albums/new"
              element={<Suspensed component={<ReleaseFormModal />} />}
            />
          </Route>
          <Route path="/releases/edit/:id" element={<Suspensed component={<EditRelease />} />} />

          <Route path="/releases/new" element={<Suspensed component={<NewRelease />} />} />
          <Route
            path="/upload"
            element={<Suspensed component={<UploadModal />} />}
          />
          <Route
            path="/upload/details"
            element={<Suspensed component={<UploadForm />} />}
          >
            <Route
              path="/upload/details/albums/new"
              element={<Suspensed component={<ReleaseFormModal />} />}
            />
          </Route>
          <Route
            path="/tnc"
            element={<Suspensed component={<TermsUpload />} />}
          />
        </Routes>
      ) : (
        <Routes>
          <Route path="/releases/new" element={<Navigate to='/releases' replace />} />
        </Routes>
      )}
    </>
  );
};

export default AppRoutes;
