import { Controller, Control, RegisterOptions } from "react-hook-form";
import SelectCore, { SelectCoreProps } from "./SelectCore";

export type SelectProps = SelectCoreProps & {
  options: {
    [x: string]: any;
  }[];
  name: string;
  control?: Control;
  errorText?: string;
  label?: string;
  labelAlt?: string;
  labelClassName?: string;
  rules?: RegisterOptions;
  findIn?: Record<'value', any>[]
  showError?: boolean;
}

const Select = ({
  control,
  name,
  label,
  labelAlt,
  labelClassName,
  rules,
  disabled,
  showError = true,
  ...rest }: SelectProps) => {

  return (
    <div className="w-full">
      {label || labelAlt ? (
        <label className={`label ${labelClassName || ""}`}>
          <span className="label-text">{label}</span>
          <span className="label-text-alt">{labelAlt}</span>
        </label>
      ) : null}
      <Controller
        name={name}
        rules={rules}
        control={control}
        render={({ field, fieldState: { error } }) => {
          return (
            <>
              <SelectCore isDisabled={disabled} {...rest} {...field} onChange={(v) => { field.onChange(v) }} />
              {error?.message && showError ? <p className="text-sm text-error">{error.message}</p> : null}
            </>
          )
        }}
      />
    </div>
  );
};

export default Select;
