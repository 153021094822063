import { FC, useRef } from "react"
import { useForm } from "react-hook-form";
import BaseInput from '@components/Inputs/BaseInput'
import TextArea from "@components/Inputs/TextArea";
import Button from "@components/Button";
import { useGraphqlMutation } from "hooks/useGraphql";
import { useNavigate } from "react-router-dom";
type MetadataViewProps = {
  video: File;
  videoId: string;
}
const AddMetadataView: FC<MetadataViewProps> = ({ video, videoId }) => {
  const videoPlaybackUrl = useRef(URL.createObjectURL(video));
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { mutate } = useGraphqlMutation()
  const navigate = useNavigate();

  const onSubmit = async (data: any) => {
    const res = await mutate({ addVideo: [{ input: { streamId: videoId, ...data } }, { id: true }] })

    if (res.data?.addVideo?.id) {
      navigate(`/videos`)
    }
  }


  return (
    <div className="p-5 gap-4 h-full">
      <form action="#" onSubmit={handleSubmit(onSubmit)} className="flex flex-col justify-between h-full">
        <div>
          <h1 className="font-bold text-lg border-b-1 pb-4 mb-4">Upload Video</h1>
          <div className="flex gap-4">
            <div className="w-full">
              <BaseInput autoFocus errorText={errors?.title?.message} {...register('title', { required: { value: true, message: 'Title is required' } })} label="Title" placeholder="Title" />
              <TextArea errorText={errors?.description?.message} {...register('description', { required: { value: true, message: 'Description is required' } })} rows={4} label="Description" placeholder="Description" />
            </div>
            <video className="w-96 h-max mt-8 rounded-md" src={videoPlaybackUrl.current} controls></video>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <p className="text-sm italic mb-2">*Video cannot be edited later</p>
          <Button type="submit">Submit</Button>
        </div>
      </form>
    </div>
  )
}

export default AddMetadataView