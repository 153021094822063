export enum ExplicitLyrics {
  NOT_EXPLICIT = 'NOT_EXPLICIT',
  EXPLICIT = 'EXPLICIT',
  CLEANED = 'CLEANED'
}

export enum PriceCategory {
  BUDGET = 'BUDGET',
  MID = 'MID',
  FULL = 'FULL',
  PREMIUM = 'PREMIUM'
}

export enum ReleaseType {
  SINGLE = 'SINGLE',
  ALBUM = 'ALBUM',
  EP = 'EP'
};

export enum LicenceType {
  COPYRIGHT = 'COPYRIGHT',
  CREATIVE_COMMONS = 'CREATIVE_COMMONS'
}

export enum CollaboratorRole {
  PRIMARY_ARTIST = 'PRIMARY_ARTIST',
  PERFORMER = 'PERFORMER',
  PRODUCER = 'PRODUCER',
  REMIXER = 'REMIXER',
  COMPOSER = 'COMPOSER',
  LYRICIST = 'LYRICIST',
  PUBLISHER = 'PUBLISHER',
  FEATURING_WITH = 'FEATURING_WITH',
  CONDUCTOR = 'CONDUCTOR',
  ARRANGER = 'ARRANGER',
  ORCHESTRA = 'ORCHESTRA',
  ACTOR = 'ACTOR',
  AGENT = 'AGENT',
  PROMOTER = 'PROMOTER',
  BENEFICIARY = 'BENEFICIARY'
}
