import Button from "@components/Button";
import Image from "@components/Image";
import Logo from "@images/ZikiTunesLogo.svg";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import BaseInput from "@components/Inputs/BaseInput";
import { useNavigate } from "react-router-dom";
import usePasswordReset from "hooks/usePasswordReset";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, watch } = useForm();
  const { isLoading, isSuccess, error, sendEmail } = usePasswordReset();

  useEffect(() => {
    if (isSuccess) {
      navigate("/reset-password", {
        state: {
          username: watch("email"),
        },
      });
    }
  }, [isSuccess]);

  const onSubmit = async (formData: any) => {
    console.log(formData);
    const email = formData.email;
    await sendEmail(email);
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <form
        className="form flex flex-col gap-2 w-80 items-center"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Image src={Logo} height="100px" width="100px" />
        <h3 className="text-2xl mb-1">Forgot Password ?</h3>
        <p className="text-sm text-center mb-4">
          Please enter your email and we will send you OTP to reset your
          password.
        </p>
        {error && (
          <div className="w-full">
            <p className={"text-error text-sm"}>{error.message}</p>
          </div>
        )}
        <BaseInput
          {...register("email", { required: true })}
          placeholder="Email"
        />
        <Button className="w-full">
          {isLoading ? "Loading..." : "Submit"}
        </Button>
      </form>
    </div>
  );
};

export default ForgotPassword;
