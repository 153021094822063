import { primaryGenres } from 'constants/Genre/primary';
import { secondaryGenres } from 'constants/Genre/secondary';
import { Controller, useFormContext } from 'react-hook-form';
import ReactSelect from 'react-select'

const Genres = () => {
  const { control } = useFormContext();

  // integrate react select with react hook form
  return (
    <>
      <div>
        <label className="label font-semibold pt-0">
          <span className="label-text">Genre</span>
        </label>
        <div className='flex w-full gap-4'>
          <Controller
            render={({ field, formState: { errors } }) => (
              <div className='flex-1'>
                <ReactSelect className='flex-1' placeholder="Primary Genre" {...field} options={primaryGenres} />
                {errors.primary_genre && <p className='text-xs text-error my-1'>{errors.primary_genre.message}</p>}
              </div>
            )
            }
            rules={{
              required: {
                value: true,
                message: 'Please select at least one song'
              }
            }}
            name='primary_genre'
            control={control}
          />

          <Controller
            render={({ field }) => (
              <ReactSelect className='flex-1' placeholder="Secondary Genre" {...field} options={secondaryGenres} />
            )
            }
            name='secondary_genre'
            control={control}
          />
        </div>
      </div>
    </>
  )
}

export default Genres