import Button from "@components/Button";
import BaseInput from "@components/Inputs/BaseInput";
import { ReactNode, useEffect } from "react";
import { Controller, useFieldArray, useFormContext, useWatch } from "react-hook-form"
import { BsXLg } from "react-icons/bs";
import ReactSelect from "react-select"
import { FormResult } from "../form";
import { RoleOptionsList } from "constants/RolesMap";
import { CollaboratorRole } from "zeus/types/song";

const RoleSelector = (props: { index: number }) => {
  return (
    <>
      <Controller
        name={`artists.${props.index}.role`}
        render={({ field }) => {
          return <div className="flex-1">
            <label htmlFor={`role-${props.index}`} className="label font-semibold"><span className="label-text">Role</span></label>
            <ReactSelect id={`role-${props.index}`} placeholder="Role" {...field} options={RoleOptionsList} />
          </div>

        }}
      />
    </>
  )
}

const NameSelector = ({ index, adornment }: { index: number, adornment?: ReactNode }) => {
  return (
    <>
      <Controller
        name={`artists.${index}.name`}
        render={({ field }) => (
          <div className="flex-1">
            <BaseInput className="h-10" {...field} adornment={adornment} adornmentPosition="right" placeholder="Name" label="Name" labelClassName="font-semibold" />
          </div>
        )}
      />
    </>
  )
}

const Artists = () => {
  const { control, formState: { errors, isSubmitting }, setError, clearErrors } = useFormContext<FormResult>()
  const { fields, append, remove } = useFieldArray({ name: 'artists', control });
  const selectedArtists = useWatch({ control, name: 'artists', defaultValue: fields || [] });

  useEffect(() => {
    if (fields.length === 0) {
      append({ name: null, role: null })
    }
  }, [fields]);

  useEffect(() => {
    const hasPrimaryArtist = selectedArtists.some(artist => artist.role?.value === CollaboratorRole.PRIMARY_ARTIST && artist.name);
    if (!hasPrimaryArtist && isSubmitting) {
      setError('artists', { type: 'required', message: 'Atleast 1 artist and their name is required' })
    }
    if (hasPrimaryArtist) {
      clearErrors('artists')
    }

  }, [selectedArtists, isSubmitting]);

  return (
    <div>
      <div className="flex">
        <label className="label font-semibold pt-0">
          <span className="label-text">Artists</span>
        </label>
      </div>
      {fields.map((field, index) => (
        <div className="flex gap-4 items-center" key={field.id}>
          <RoleSelector index={index} key={`role-${field.id}`} />
          <NameSelector index={index} key={`name-${field.id}`} adornment={
            fields.length !== 1 ? <div className="flex items-center h-8">
              <Button onClick={() => remove(index)} type='button' className="btn btn-xs btn-circle btn-outline">
                <BsXLg />
              </Button>
            </div> : null
          } />

        </div>
      ))}

      {errors.artists ? <p className='text-xs text-error my-1'>{(errors.artists as any)?.message}</p> : null}

      <div className="flex justify-end mt-4">
        <Button className="w-max" type="button" onClick={() => append({ role: null, name: null })}>Add Artist</Button>
      </div>
    </div>
  )
}

export default Artists