export const primaryGenres = [
  { "label": "Alternative", "value": "ALTERNATIVE" },
  { "label": "Alternative/Experimental", "value": "ALTERNATIVE/EXPERIMENTAL" },
  { "label": "Alternative/Gothic", "value": "ALTERNATIVE/GOTHIC" },
  { "label": "Alternative/Grunge", "value": "ALTERNATIVE/GRUNGE" },
  { "label": "Alternative/Indie Pop", "value": "ALTERNATIVE/INDIE POP" },
  { "label": "Alternative/Indie Rock", "value": "ALTERNATIVE/INDIE ROCK" },
  { "label": "Alternative/Rock", "value": "ALTERNATIVE/ROCK" },
  { "label": "Ambient/New Age", "value": "AMBIENT/NEW AGE" },
  { "label": "Ambient/New Age/Meditation", "value": "AMBIENT/NEW AGE/MEDITATION" },
  { "label": "Blues", "value": "BLUES" },
  { "label": "Blues/Contemporary Blues", "value": "BLUES/CONTEMPORARY BLUES" },
  { "label": "Blues/New Orleans Blues", "value": "BLUES/NEW ORLEANS BLUES" },
  { "label": "Blues/Traditional Blues", "value": "BLUES/TRADITIONAL BLUES" },
  { "label": "Children’s Music", "value": "CHILDREN’S MUSIC" },
  { "label": "Children’s Music/Classic", "value": "CHILDREN’S MUSIC/CLASSIC" },
  { "label": "Children’s Music/Holiday", "value": "CHILDREN’S MUSIC/HOLIDAY" },
  { "label": "Children’s Music/Stories", "value": "CHILDREN’S MUSIC/STORIES" },
  { "label": "Classical", "value": "CLASSICAL" },
  { "label": "Classical/Antique", "value": "CLASSICAL/ANTIQUE" },
  { "label": "Classical/Baroque", "value": "CLASSICAL/BAROQUE" },
  { "label": "Classical/Chamber", "value": "CLASSICAL/CHAMBER" },
  { "label": "Classical/Concert", "value": "CLASSICAL/CONCERT" },
  { "label": "Classical/Modern Compositions", "value": "CLASSICAL/MODERN COMPOSITIONS" },
  { "label": "Classical/Opera", "value": "CLASSICAL/OPERA" },
  { "label": "Classical/Orchestral", "value": "CLASSICAL/ORCHESTRAL" },
  { "label": "Classical/Piano", "value": "CLASSICAL/PIANO" },
  { "label": "Classical/Romantic", "value": "CLASSICAL/ROMANTIC" },
  { "label": "Comedy", "value": "COMEDY" },
  { "label": "Country", "value": "COUNTRY" },
  { "label": "Country/Bluegrass", "value": "COUNTRY/BLUEGRASS" },
  { "label": "Country/Contemporary", "value": "COUNTRY/CONTEMPORARY" },
  { "label": "Country/Honky Tonk", "value": "COUNTRY/HONKY TONK" },
  { "label": "Country/Nashville", "value": "COUNTRY/NASHVILLE" },
  { "label": "Country/Pop", "value": "COUNTRY/POP" },
  { "label": "Country/Square Dance", "value": "COUNTRY/SQUARE DANCE" },
  { "label": "Easy Listening", "value": "EASY LISTENING" },
  { "label": "Easy Listening/Bar Jazz/Cocktail", "value": "EASY LISTENING/BAR JAZZ/COCKTAIL" },
  { "label": "Easy Listening/Bossa Nova", "value": "EASY LISTENING/BOSSA NOVA" },
  { "label": "Easy Listening/Lounge", "value": "EASY LISTENING/LOUNGE" },
  { "label": "Easy Listening/Traditional", "value": "EASY LISTENING/TRADITIONAL" },
  { "label": "Electronic", "value": "ELECTRONIC" },
  { "label": "Electronic/Acid House", "value": "ELECTRONIC/ACID HOUSE" },
  { "label": "Electronic/Breaks", "value": "ELECTRONIC/BREAKS" },
  { "label": "Electronic/Broken beat", "value": "ELECTRONIC/BROKEN BEAT" },
  { "label": "Electronic/Chill Out", "value": "ELECTRONIC/CHILL OUT" },
  { "label": "Electronic/DJ Tools/Sample Packs", "value": "ELECTRONIC/DJ TOOLS/SAMPLE PACKS" },
  { "label": "Electronic/Dance", "value": "ELECTRONIC/DANCE" },
  { "label": "Electronic/Deep House", "value": "ELECTRONIC/DEEP HOUSE" },
  { "label": "Electronic/Downtempo - experimental", "value": "ELECTRONIC/DOWNTEMPO - EXPERIMENTAL" },
  { "label": "Electronic/Drum & Bass", "value": "ELECTRONIC/DRUM & BASS" },
  { "label": "Electronic/Dub/Reggae/Dancehall", "value": "ELECTRONIC/DUB/REGGAE/DANCEHALL" },
  { "label": "Electronic/Dubstep/Grime", "value": "ELECTRONIC/DUBSTEP/GRIME" },
  { "label": "Electronic/Electro House", "value": "ELECTRONIC/ELECTRO HOUSE" },
  { "label": "Electronic/Glitch Hop", "value": "ELECTRONIC/GLITCH HOP" },
  { "label": "Electronic/Hard Dance", "value": "ELECTRONIC/HARD DANCE" },
  { "label": "Electronic/Hard Techno", "value": "ELECTRONIC/HARD TECHNO" },
  { "label": "Electronic/Hardcore", "value": "ELECTRONIC/HARDCORE" },
  { "label": "Electronic/Hardstyle", "value": "ELECTRONIC/HARDSTYLE" },
  { "label": "Electronic/House", "value": "ELECTRONIC/HOUSE" },
  { "label": "Electronic/Indie Dance/Nu Disco", "value": "ELECTRONIC/INDIE DANCE/NU DISCO" },
  { "label": "Electronic/Jazz", "value": "ELECTRONIC/JAZZ" },
  { "label": "Electronic/Minimal", "value": "ELECTRONIC/MINIMAL" },
  { "label": "Electronic/Pop Trance", "value": "ELECTRONIC/POP TRANCE" },
  { "label": "Electronic/Progressive House", "value": "ELECTRONIC/PROGRESSIVE HOUSE" },
  { "label": "Electronic/Psy-Trance", "value": "ELECTRONIC/PSY-TRANCE" },
  { "label": "Electronic/Tech House", "value": "ELECTRONIC/TECH HOUSE" },
  { "label": "Electronic/Techno", "value": "ELECTRONIC/TECHNO" },
  { "label": "Electronic/Trance", "value": "ELECTRONIC/TRANCE" },
  { "label": "Electronic/Trip Hop", "value": "ELECTRONIC/TRIP HOP" },
  { "label": "Experimental", "value": "EXPERIMENTAL" },
  { "label": "Fitness&Workout", "value": "FITNESS&WORKOUT" },
  { "label": "Flamenco", "value": "FLAMENCO" },
  { "label": "Folk", "value": "FOLK" },
  { "label": "Funk/R&B", "value": "FUNK/R&B" },
  { "label": "Hip-Hop/Rap", "value": "HIP-HOP/RAP" },
  { "label": "Hip-Hop/Rap/Gangsta & Hardcore", "value": "HIP-HOP/RAP/GANGSTA & HARDCORE" },
  { "label": "Holiday/Christmas", "value": "HOLIDAY/CHRISTMAS" },
  { "label": "Inspirational", "value": "INSPIRATIONAL" },
  { "label": "Jazz", "value": "JAZZ" },
  { "label": "Jazz/Bebop", "value": "JAZZ/BEBOP" },
  { "label": "Jazz/Big Band", "value": "JAZZ/BIG BAND" },
  { "label": "Jazz/Brazilian Jazz", "value": "JAZZ/BRAZILIAN JAZZ" },
  { "label": "Jazz/Classic", "value": "JAZZ/CLASSIC" },
  { "label": "Jazz/Contemporary", "value": "JAZZ/CONTEMPORARY" },
  { "label": "Jazz/Dixie/Rag Time", "value": "JAZZ/DIXIE/RAG TIME" },
  { "label": "Jazz/Free Jazz", "value": "JAZZ/FREE JAZZ" },
  { "label": "Jazz/Fusion", "value": "JAZZ/FUSION" },
  { "label": "Jazz/Jazz Funk", "value": "JAZZ/JAZZ FUNK" },
  { "label": "Jazz/Latin Jazz", "value": "JAZZ/LATIN JAZZ" },
  { "label": "Jazz/Nu Jazz/Acid Jazz", "value": "JAZZ/NU JAZZ/ACID JAZZ" },
  { "label": "Jazz/Smooth Jazz", "value": "JAZZ/SMOOTH JAZZ" },
  { "label": "Jazz/Swing", "value": "JAZZ/SWING" },
  { "label": "Jazz/Traditional", "value": "JAZZ/TRADITIONAL" },
  { "label": "Jazz/World", "value": "JAZZ/WORLD" },
  { "label": "Karaoke", "value": "KARAOKE" },
  { "label": "Latin", "value": "LATIN" },
  { "label": "Latin/Bachata", "value": "LATIN/BACHATA" },
  { "label": "Latin/Banda", "value": "LATIN/BANDA" },
  { "label": "Latin/Big Band", "value": "LATIN/BIG BAND" },
  { "label": "Latin/Bolero", "value": "LATIN/BOLERO" },
  { "label": "Latin/Bossa Nova", "value": "LATIN/BOSSA NOVA" },
  { "label": "Latin/Brasil/Tropical", "value": "LATIN/BRASIL/TROPICAL" },
  { "label": "Latin/Christian", "value": "LATIN/CHRISTIAN" },
  { "label": "Latin/Conjunto", "value": "LATIN/CONJUNTO" },
  { "label": "Latin/Corridos", "value": "LATIN/CORRIDOS" },
  { "label": "Latin/Cuban", "value": "LATIN/CUBAN" },
  { "label": "Latin/Cumbia", "value": "LATIN/CUMBIA" },
  { "label": "Latin/Duranguense", "value": "LATIN/DURANGUENSE" },
  { "label": "Latin/Electronica", "value": "LATIN/ELECTRONICA" },
  { "label": "Latin/Grupero", "value": "LATIN/GRUPERO" },
  { "label": "Latin/Hip Hop", "value": "LATIN/HIP HOP" },
  { "label": "Latin/Latin Rap", "value": "LATIN/LATIN RAP" },
  { "label": "Latin/Mambo", "value": "LATIN/MAMBO" },
  { "label": "Latin/Mariachi", "value": "LATIN/MARIACHI" },
  { "label": "Latin/Norteño", "value": "LATIN/NORTEÑO" },
  { "label": "Latin/Pop", "value": "LATIN/POP" },
  { "label": "Latin/Ranchera", "value": "LATIN/RANCHERA" },
  { "label": "Latin/Reggaeton", "value": "LATIN/REGGAETON" },
  { "label": "Latin/Regional Mexicano", "value": "LATIN/REGIONAL MEXICANO" },
  { "label": "Latin/Rock en Español", "value": "LATIN/ROCK EN ESPAÑOL" },
  { "label": "Latin/Salsa", "value": "LATIN/SALSA" },
  { "label": "Latin/Salsa/Merengue", "value": "LATIN/SALSA/MERENGUE" },
  { "label": "Latin/Sierreño", "value": "LATIN/SIERREÑO" },
  { "label": "Latin/Sonidero", "value": "LATIN/SONIDERO" },
  { "label": "Latin/Tango", "value": "LATIN/TANGO" },
  { "label": "Latin/Tejano", "value": "LATIN/TEJANO" },
  { "label": "Latin/Tierra Caliente", "value": "LATIN/TIERRA CALIENTE" },
  { "label": "Latin/Traditional Mexican", "value": "LATIN/TRADITIONAL MEXICAN" },
  { "label": "Latin/Vallenato", "value": "LATIN/VALLENATO" },
  { "label": "New Age", "value": "NEW AGE" },
  { "label": "Pop", "value": "POP" },
  { "label": "Pop/Contemporary/Adult", "value": "POP/CONTEMPORARY/ADULT" },
  { "label": "Pop/J-Pop", "value": "POP/J-POP" },
  { "label": "Pop/K-Pop", "value": "POP/K-POP" },
  { "label": "Pop/Mandopop", "value": "POP/MANDOPOP" },
  { "label": "Pop/Singer Songwriter", "value": "POP/SINGER SONGWRITER" },
  { "label": "Punk", "value": "PUNK" },
  { "label": "R&B", "value": "R&B" },
  { "label": "Reggae", "value": "REGGAE" },
  { "label": "Rock", "value": "ROCK" },
  { "label": "Rock/Brit-Pop", "value": "ROCK/BRIT-POP" },
  { "label": "Rock/Classic", "value": "ROCK/CLASSIC" },
  { "label": "Rock/Glam Rock", "value": "ROCK/GLAM ROCK" },
  { "label": "Rock/Hard Rock/Heavy Metal", "value": "ROCK/HARD ROCK/HEAVY METAL" },
  { "label": "Rock/Heavy Metal", "value": "ROCK/HEAVY METAL" },
  { "label": "Rock/Progressive", "value": "ROCK/PROGRESSIVE" },
  { "label": "Rock/Rock ‘n’ Roll", "value": "ROCK/ROCK ‘N’ ROLL" },
  { "label": "Rock/Singer/Songwriter", "value": "ROCK/SINGER/SONGWRITER" },
  { "label": "Ska", "value": "SKA" },
  { "label": "Soul", "value": "SOUL" },
  { "label": "Soundtrack", "value": "SOUNDTRACK" },
  { "label": "Soundtrack/Anime", "value": "SOUNDTRACK/ANIME" },
  { "label": "Soundtrack/Musical", "value": "SOUNDTRACK/MUSICAL" },
  { "label": "Soundtrack/TV", "value": "SOUNDTRACK/TV" },
  { "label": "Spiritual", "value": "SPIRITUAL" },
  { "label": "Spiritual/Christian", "value": "SPIRITUAL/CHRISTIAN" },
  { "label": "Spiritual/Gospel", "value": "SPIRITUAL/GOSPEL" },
  { "label": "Spiritual/Gregorian", "value": "SPIRITUAL/GREGORIAN" },
  { "label": "Spiritual/India", "value": "SPIRITUAL/INDIA" },
  { "label": "Spiritual/Judaica", "value": "SPIRITUAL/JUDAICA" },
  { "label": "Spiritual/World", "value": "SPIRITUAL/WORLD" },
  { "label": "Spoken Word/Speeches", "value": "SPOKEN WORD/SPEECHES" },
  { "label": "Trap", "value": "TRAP" },
  { "label": "Trap/Future Bass", "value": "TRAP/FUTURE BASS" },
  { "label": "Trap/Future Bass/Twerk", "value": "TRAP/FUTURE BASS/TWERK" },
  { "label": "Vocal/Nostalgia", "value": "VOCAL/NOSTALGIA" },
  { "label": "World", "value": "WORLD" },
  { "label": "World/African", "value": "WORLD/AFRICAN" },
  { "label": "World/Afro-Beat", "value": "WORLD/AFRO-BEAT" },
  { "label": "World/Afro-Pop", "value": "WORLD/AFRO-POP" },
  { "label": "World/Americas/Argentina", "value": "WORLD/AMERICAS/ARGENTINA" },
  { "label": "World/Americas/Brazilian", "value": "WORLD/AMERICAS/BRAZILIAN" },
  { "label": "World/Americas/Brazilian/Axé", "value": "WORLD/AMERICAS/BRAZILIAN/AXÉ" },
  { "label": "World/Americas/Brazilian/Black Music", "value": "WORLD/AMERICAS/BRAZILIAN/BLACK MUSIC" },
  { "label": "World/Americas/Brazilian/Bossa Nova", "value": "WORLD/AMERICAS/BRAZILIAN/BOSSA NOVA" },
  { "label": "World/Americas/Brazilian/Chorinho", "value": "WORLD/AMERICAS/BRAZILIAN/CHORINHO" },
  { "label": "World/Americas/Brazilian/Folk", "value": "WORLD/AMERICAS/BRAZILIAN/FOLK" },
  { "label": "World/Americas/Brazilian/Funk Carioca", "value": "WORLD/AMERICAS/BRAZILIAN/FUNK CARIOCA" },
  { "label": "World/Americas/Brazilian/MPB", "value": "WORLD/AMERICAS/BRAZILIAN/MPB" },
  { "label": "World/Americas/Brazilian/Marchinha", "value": "WORLD/AMERICAS/BRAZILIAN/MARCHINHA" },
  { "label": "World/Americas/Brazilian/Pagode", "value": "WORLD/AMERICAS/BRAZILIAN/PAGODE" },
  { "label": "World/Americas/Brazilian/Samba", "value": "WORLD/AMERICAS/BRAZILIAN/SAMBA" },
  { "label": "World/Americas/Brazilian/Samba-Rock", "value": "WORLD/AMERICAS/BRAZILIAN/SAMBA-ROCK" },
  { "label": "World/Americas/Brazilian/Samba-de-Raiz", "value": "WORLD/AMERICAS/BRAZILIAN/SAMBA-DE-RAIZ" },
  { "label": "World/Americas/Brazilian/Samba-enredo", "value": "WORLD/AMERICAS/BRAZILIAN/SAMBA-ENREDO" },
  { "label": "World/Americas/Brazilian/Sambalanço", "value": "WORLD/AMERICAS/BRAZILIAN/SAMBALANÇO" },
  { "label": "World/Americas/Cajun-Creole", "value": "WORLD/AMERICAS/CAJUN-CREOLE" },
  { "label": "World/Americas/Calypso", "value": "WORLD/AMERICAS/CALYPSO" },
  { "label": "World/Americas/Colombia", "value": "WORLD/AMERICAS/COLOMBIA" },
  { "label": "World/Americas/Cuba-Caribbean", "value": "WORLD/AMERICAS/CUBA-CARIBBEAN" },
  { "label": "World/Americas/Mexican", "value": "WORLD/AMERICAS/MEXICAN" },
  { "label": "World/Americas/North-American", "value": "WORLD/AMERICAS/NORTH-AMERICAN" },
  { "label": "World/Americas/Panama", "value": "WORLD/AMERICAS/PANAMA" },
  { "label": "World/Americas/Peru", "value": "WORLD/AMERICAS/PERU" },
  { "label": "World/Americas/South-American", "value": "WORLD/AMERICAS/SOUTH-AMERICAN" },
  { "label": "World/Arabic", "value": "WORLD/ARABIC" },
  { "label": "World/Asian/Central Asia", "value": "WORLD/ASIAN/CENTRAL ASIA" },
  { "label": "World/Asian/China", "value": "WORLD/ASIAN/CHINA" },
  { "label": "World/Asian/India", "value": "WORLD/ASIAN/INDIA" },
  { "label": "World/Asian/India-Bollywood", "value": "WORLD/ASIAN/INDIA-BOLLYWOOD" },
  { "label": "World/Asian/Japan", "value": "WORLD/ASIAN/JAPAN" },
  { "label": "World/Asian/South Asia", "value": "WORLD/ASIAN/SOUTH ASIA" },
  { "label": "World/Australian/Pacific", "value": "WORLD/AUSTRALIAN/PACIFIC" },
  { "label": "World/Ethnic", "value": "WORLD/ETHNIC" },
  { "label": "World/Europe/Eastern", "value": "WORLD/EUROPE/EASTERN" },
  { "label": "World/Europe/French", "value": "WORLD/EUROPE/FRENCH" },
  { "label": "World/Europe/German", "value": "WORLD/EUROPE/GERMAN" },
  { "label": "World/Europe/Northern", "value": "WORLD/EUROPE/NORTHERN" },
  { "label": "World/Europe/Southern", "value": "WORLD/EUROPE/SOUTHERN" },
  { "label": "World/Europe/Spain", "value": "WORLD/EUROPE/SPAIN" },
  { "label": "World/Europe/Western", "value": "WORLD/EUROPE/WESTERN" },
  { "label": "World/Mediterranean/Greece", "value": "WORLD/MEDITERRANEAN/GREECE" },
  { "label": "World/Mediterranean/Italy", "value": "WORLD/MEDITERRANEAN/ITALY" },
  { "label": "World/Mediterranean/Spain", "value": "WORLD/MEDITERRANEAN/SPAIN" },
  { "label": "World/Russian", "value": "WORLD/RUSSIAN" },
  { "label": "World/Worldbea", "value": "WORLD/WORLDBEA" }
]