import {
  AuthState,
  updateAuth,
  UserAttributes,
  customAttributes,
} from "@store/authSlice";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { useAppDispatch } from "hooks";
import { RegisterProps } from "types/form";
import { getCountryNameByPhone } from "@utils/country";
import getUsername from "@utils/getUsername";

const useAuth = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const signIn = async (emailID: string, password: string) => {
    setIsLoading(true);
    try {
      const response = await Auth.signIn({
        username: emailID,
        password,
      });
      const currentUser = await Auth.currentUserInfo();

      const {
        name,
        phone_number,
        picture,
        phone_number_verified,
        email,
        email_verified,
        address,
        locale,
        website,
        timezone,
        updated_at,
      } = response.attributes;

      const userAttributes: UserAttributes = {
        name,
        phone_number,
        phone_number_verified,
        email,
        email_verified,
        locale,
        address,
        picture,
        website,
        timezone,
        updated_at,
      };

      customAttributes.forEach((attr) => {
        // @ts-ignore
        // NOTE: Fix TS error
        userAttributes[attr] = response.attributes[`custom:${attr}`];
      });

      const authRes: AuthState = {
        isAuthenticated: true,
        user: userAttributes,
        details: {
          id: currentUser.id,
          username: currentUser.username,
          auth: {
            token: response.signInUserSession.accessToken.jwtToken,
            expiry_at: response.signInUserSession.accessToken.payload.exp,
            iat: response.signInUserSession.accessToken.payload.iat,
          },
        },
      };

      return dispatch(updateAuth(authRes));
    } catch (error: any) {
      // console.log("SignIn Error", error);
      const errorMsg = error.message ?? "Unknown error occured!";
      setError(errorMsg);
      throw new Error(errorMsg);
    } finally {
      setIsLoading(false);
    }
  };

  const signUp = async (formData: RegisterProps) => {
    setIsLoading(true);
    const userLocale =
      navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language;
    try {
      const response = await Auth.signUp({
        username: formData.email.toLowerCase().trim(),
        password: formData.password,
        attributes: {
          email: formData.email,
          phone_number: formData?.phone,
          updated_at: Date.now().toString(),
          name: formData.name,
          //TODO: Generate avatar on user register on backend
          picture: "ziki-avatar",
          preferred_username: getUsername(formData.email),
          locale: userLocale,
          "custom:country": getCountryNameByPhone(formData.country_code.value),
          "custom:is_artist": "yes",
        },
      });

      return response;
    } catch (error: any) {
      // console.log("SignUp Error", error);
      const errorMsg = error.message ?? "Unknown error occured!";
      setError(errorMsg);
      throw new Error(errorMsg);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, error, signIn, signUp };
};

export default useAuth;
