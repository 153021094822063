import { twMerge } from 'tailwind-merge';
import MonitorArrowUp from '../../../assets/images/monitor-arrow-up.svg';
import DropVideo from './DropVideo';
import useVideo from 'hooks/api/useVideo';
import { FC, useState } from 'react';


type UploadViewProps = {
  onProgress?: (progress: number | null) => void;
  onSuccess?: (streamId: string | undefined) => void;
  onFile?: (file: File) => void;
}

const UploadView: FC<UploadViewProps> = ({ onProgress, onSuccess, onFile }) => {
  const { upload } = useVideo();
  const [hasSelected, setHasSelected] = useState(false)
  const onDrop = async (file: File) => {
    onFile?.(file);
    setHasSelected(true)
    const streamId = await upload(file, {
      chunkSize: 20 * 1024 * 1024,
      onProgress: (bytesSent, bytesTotal) => {
        const progress = ((bytesSent / bytesTotal) * 100);
        onProgress?.(progress)
      },
      onSuccess: () => {
        onProgress?.(null)
      }
    });
    onSuccess?.(streamId);
  }


  return (
    <DropVideo onFileDrop={onDrop}>
      {({ dropping, getInputProps, getRootProps }) => (
        <div className={twMerge("h-full flex flex-col", dropping && !hasSelected && "bg-base-200 cursor-not-allowed")} {...(hasSelected ? ({}) : getRootProps())}>
          <input {...getInputProps({ accept: "video/*", multiple: false, disabled: hasSelected })} />

          <p className="w-full h-full flex flex-col items-center justify-center">
            <img src={MonitorArrowUp} className='w-20' alt="" />
            Drag and drop or click to upload
          </p>
        </div>
      )}
    </DropVideo>
  )
}

export default UploadView