import BaseInput from "@components/Inputs/BaseInput"
import { Controller, useFormContext } from "react-hook-form"

const Label = () => {
  const { control } = useFormContext();
  return (
    <div>

      <Controller
        name="label"
        control={control}
        defaultValue={""}
        rules={{ required: { value: true, message: 'Label is required' } }}
        render={({ field, fieldState: { error } }) => (
          <>
            <BaseInput {...field} placeholder="Label" label="Label" labelClassName="font-semibold" />
            <p className="text-xs text-error my-1">{error?.message}</p>
          </>
        )} />
    </div>
  )
}

export default Label