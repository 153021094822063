import useSongs from 'hooks/api/useSongs';
import { Controller, useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import ReactSelect from 'react-select';
import { HiArrowCircleRight } from 'react-icons/hi'

const SelectSongs = () => {
  const { songs } = useSongs(['title']);
  const { control } = useFormContext();

  const songOptions = songs?.map((song) => ({
    value: song?.id,
    label: song?.title
  }))

  // integrate react select with react hook form
  return (
    <>
      <div>
        <label className="label font-semibold pt-0">
          <span className="label-text">Select songs</span>
          <Link to="/upload" className="label-text-alt flex items-center gap-0.5 text-slate-500 font-normal">Upload new song
            <span>
              <HiArrowCircleRight className='-rotate-45 w-4 h-4' />
            </span>
          </Link>
        </label>
        <Controller
          render={({ field, formState: { errors } }) => (
            <div id="select-songs">
              <ReactSelect isMulti {...field} options={songOptions} />
              {errors.songs && <p className='text-xs text-error my-1'>{errors.songs.message}</p>}
            </div>
          )
          }
          rules={{
            // required: {
            //   value: true,
            //   message: 'Please select at least one song'
            // }
          }}
          name='songs'
          control={control}
        />
      </div>
    </>
  )
}

export default SelectSongs