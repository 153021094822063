import { Controller, useFormContext } from "react-hook-form";
import ReactSelect from "react-select";
import { ReleaseType as EnumReleaseType } from "zeus";
const ReleaseType = () => {
  const { control } = useFormContext();
  return (
    <div>
      <label className="label font-semibold pt-0">
        <span className="label-text">Release Type</span>
      </label>
      <Controller name="release-type" control={control} rules={{ required: { value: true, message: 'Release type is required' } }} render={({ field, formState: { errors } }) => (
        <>
          <ReactSelect {...field} options={[{ label: 'EP', value: EnumReleaseType.EP }, { label: 'Single', value: EnumReleaseType.SINGLE }, { label: 'Album', value: EnumReleaseType.ALBUM }, { label: 'Compilation', value: EnumReleaseType.COMPILATION }]} />
          {errors?.['release-type'] && <p className='text-xs text-error my-1'>{errors['release-type'].message}</p>}
        </>
      )} />
    </div>
  )
}

export default ReleaseType