import { Radio, RadioGroup } from "@components/Radio"
import { useFormContext } from "react-hook-form"
import { LicenceType as EnumLicenseType } from "zeus";

const LicenseType = () => {
  const { control } = useFormContext()
  return (
    <div>
      <label className="label font-semibold pt-0">
        <span className="label-text">License Type</span>
      </label>
      <RadioGroup radioRootClassName="gap-2" className='my-2 ml-2' name="licence-type" defaultValue={EnumLicenseType.COPYRIGHT} control={control}>
        <Radio label="Copyrighted" value={EnumLicenseType.COPYRIGHT} />
        <Radio label="Creative Commons" value={EnumLicenseType.CREATIVE_COMMONS} />
      </RadioGroup>
    </div>
  )
}

export default LicenseType