import { useEffect, useState } from "react";
import ReactSelect, { MultiValue, Props } from "react-select";
import Regions from './region.dataset.json'
import { MultiRegionOption } from "./RegionSelector.d";
import { getSelectedRegions, getUnselectedRegions } from "./regionTransformer";

export type RegionSelectorCoreProps = Props & {
  onChange?: (value: string[] | undefined) => void,
  type?: 'default' | 'unselected';
  values: string[] | undefined;
}

const RegionSelectorCore = ({ values, onChange, type = 'default', ...props }: RegionSelectorCoreProps) => {
  const [regions, setRegions] = useState(Regions);
  const [selectedRegions, setSelectedRegions] = useState<MultiRegionOption>([]);

  const handleOnChange = (options: MultiValue<{ label: string, value?: string }>) => {

    // reset values if 'options' is empty
    switch (true) {
      case !options.length:
      case selectedRegions.map(o => o.value).includes('WORLD') && !options.length:
        onChange?.([]);
        setRegions(Regions);
        return;
      default:
        break;
    }


    // check if user has selected 'Whole World'
    if (options.map(o => o.value).includes('WORLD')) {
      onChange?.(type === 'unselected' ? getUnselectedRegions(['WORLD']) : ['WORLD']);
      setRegions([])
      return;
    }

    const selectedRegions2 = options.map((option) => Regions.filter(region => region.label === option.value)[0]?.label)

    // check if there is a country of selected region and remove it

    let finalOptions;

    selectedRegions2.forEach((region) => {
      const selectingRegion = Regions.find(r => r.label === region);
      const countriesOfRegion = selectingRegion?.options?.map(o => o.value).filter(o => o !== selectingRegion.label)

      const newOptions = options.filter((o) => o.value && !countriesOfRegion?.includes(o.value))

      finalOptions = newOptions;
    })

    // remove selected region's list from dropdown
    const dropdownRegions = Regions.filter((region) => !selectedRegions2?.includes(region.label))
    setRegions(dropdownRegions)

    const values = (finalOptions || options).map(o => o.value).filter(o => o) as string[]

    onChange?.(values.length ? (type === 'unselected' ? getUnselectedRegions(values) : values) : undefined);
  }

  useEffect(() => {
    let selectValue = values;
    if (type === 'unselected') {
      selectValue = getSelectedRegions(values)
    }

    if (selectValue?.includes('WORLD')) {
      setSelectedRegions([{ label: 'Whole World', value: 'WORLD' }])
    } else {
      const results: MultiRegionOption = [];
      Regions.forEach((region) => {
        if (region.options) {
          const containingOptions = region.options.filter(option => selectValue?.includes(option.value));
          results.push(...(containingOptions || []))
        }
      });
      setSelectedRegions(results)
    }
  }, [values, type])

  return (
    <ReactSelect
      {...props as any}
      isMulti
      value={selectedRegions}
      options={regions}
      onChange={handleOnChange}
    />
  )
}

export default RegionSelectorCore