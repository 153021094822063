import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";

import AppRoutes from "./AppRoutes";
import {
  AuthState,
  updateAuth,
  UserAttributes,
  customAttributes,
} from "@store/authSlice";
import { useAppDispatch } from "hooks";
import Loader from "@components/Loader";
import amplifyAuth from "config/amplifyAuth";

const { ENV_STORAGE_REGION, ENV_RAW_AUDIO_BUCKET, ENV_GQL_ENDPOINT } = import.meta.env;

const App = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const getAuthenticatedUser = async () => {
    try {
      const { attributes, signInUserSession } = await Auth.currentAuthenticatedUser();
      const { id: cognitoId, username } = await Auth.currentUserInfo();

      const {
        name,
        phone_number,
        picture,
        phone_number_verified,
        email,
        email_verified,
        address,
        locale,
        website,
        timezone,
        updated_at,
        based_country,
        based_region,
      } = attributes;

      const userAttributes: UserAttributes = {
        name,
        phone_number,
        phone_number_verified,
        email,
        email_verified,
        locale,
        address,
        picture,
        website,
        timezone,
        updated_at,
        based_country,
        based_region,
      };

      customAttributes.forEach((attr) => {
        // @ts-ignore
        // NOTE: Fix TS error
        userAttributes[attr] = attributes[`custom:${attr}`];
      });

      const authState: AuthState = {
        isAuthenticated: true,
        user: userAttributes,
        details: {
          id: cognitoId,
          username: username,
          auth: {
            token: signInUserSession.accessToken.jwtToken,
            expiry_at: signInUserSession.accessToken.payload.exp,
            iat: signInUserSession.accessToken.payload.iat,
          },
        },
      };

      dispatch(updateAuth(authState));
    } catch (error) {
      console.log("Error occurred while authenticating", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAuthenticatedUser();
  }, []);

  useEffect(() => {
    Amplify.configure({
      aws_appsync_graphqlEndpoint: ENV_GQL_ENDPOINT,
      aws_appsync_region: ENV_STORAGE_REGION,
      aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
      Auth: amplifyAuth,
      Storage: {
        AWSS3: {
          bucket: ENV_RAW_AUDIO_BUCKET, // REQUIRED -  Amazon S3 bucket name
          region: ENV_STORAGE_REGION,
        },
      },
    });
  }, []);
  return (
    <BrowserRouter>{isLoading ? <Loader /> : <AppRoutes />}</BrowserRouter>
  );
};

export default App;
