import Image from "@components/Image";
import Logo from "@images/ZikiTunesLogo.svg";

const Loader = () => {
  return (
    <>
      <div className="flex h-screen">
        <div className="m-auto">
          <Image
            cdn={false}
            src={Logo}
            className="loading-logo"
            height="100px"
            width="100px"
          />{" "}
          <h1 className="mt-2">Loading...</h1>
        </div>
      </div>
    </>
  );
};

export default Loader;
