import { useFormContext } from "react-hook-form"
import Preview from "./cover-art.preview"
import { useEffect, useState } from "react";

const CoverArt = () => {
  const { register, formState: { errors }, getValues } = useFormContext();
  const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);

  useEffect(() => {
    const coverArt = getValues('cover-art');
    if (typeof coverArt === 'string') {
      setImageSrc(coverArt)
    }
  }, []);

  const validator = (selections: FileList) => {
    return new Promise<string | { src: string }>((resolve) => {

      if (typeof selections === 'string') {
        return resolve({ src: selections });
      }

      if (selections.length > 1) {
        return resolve('Please select only one file')
      }


      const file = selections[0];

      if (!file) {
        return resolve('Please select a file')
      }

      if (file.size > 36 * 1024 * 1024) {
        return resolve('Please select an image less than 36MB.');
      }

      if (!file.type.startsWith('image')) {
        return resolve('Please select an image file')
      }

      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        return resolve('Please only select JPG or PNG file.')
      }

      const dummyImg = document.createElement('img');

      dummyImg.src = URL.createObjectURL(file);

      dummyImg.onload = () => {
        if (!(dummyImg.naturalWidth >= 3000)) {
          return resolve('Please select a file with at least 3000 pixels')
        }
        if (!(dummyImg.naturalWidth <= 5000)) {
          return resolve('Please select a file with maximum 5000 pixels')
        }

        if (dummyImg.naturalHeight !== dummyImg.naturalWidth) {
          return resolve(`Please select a proper square image, got ${dummyImg.naturalWidth} x ${dummyImg.naturalHeight} pixels.`)
        }

        // add file upload

        resolve({ src: dummyImg.src })
      }
    })
  }

  const validate = async (files: FileList) => {
    const result = await validator(files);
    if (typeof result === 'object') {
      setImageSrc(result.src);
      return true
    }

    return result
  }

  return (
    <div>
      <label className="label font-semibold pt-0">
        <span className="label-text">Cover Art</span>
      </label>
      <label htmlFor="cover-art" className={`input input-bordered rounded-md w-full min-h-16 h-max p-4 flex cursor-pointer items-center ${errors['cover-art'] ? 'input-error' : ''}`}>
        <Preview image={imageSrc} />
        <div className="ml-4">
          <p className="font-semibold">Choose File</p>
          <p className="mt-2 text-sm">Requirements -</p>
          <ol className="list-decimal ml-4 text-xs mt-1">
            <li>
              File should be within 3000 ~ 5000 pixel (square)
            </li>
            <li>
              Format should be JPG or PNG. (Max 36 MB; RGB Mode)
            </li>
          </ol>

          <div className="text-xs mt-6 break-words w-11/12 flex flex-col gap-1">
            <p>You must own the rights of the artwork and every image or visual element in it.</p>
            <p>The information on the artwork has to be consistent with all the information entered in the release metadata.</p>
            <p>Stores will reject blurry images, low quality, or if it contains any URL, e-mail, phone number, barcode, price or registered trademark logos.</p>
            <p>It must not contain any explicit or outrageous material such as pornography or hate speech. Any artwork not meeting the store's requirements will be rejected.</p>
          </div>
        </div>
      </label>
      <p className="text-xs text-error mt-1">{errors['cover-art']?.message}</p>

      <input type="file" id="cover-art" {...register('cover-art', { validate })} className="hidden" />
    </div>
  )
}

export default CoverArt