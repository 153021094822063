import BaseInput from "@components/Inputs/BaseInput";
import Select from "@components/Inputs/Select";
import primaryLanguagesMap from "constants/primaryLanguageMap";
import { useFormContext } from "react-hook-form";

const Title = () => {
  const { register, control, formState: { errors } } = useFormContext();

  return (
    <div>
      <BaseInput
        label="Title"
        {...register('title', { required: { value: true, message: 'Title is required' } })}
        placeholder="Title"
        labelClassName="font-semibold pt-0"
        errorText={[errors?.title?.message, errors?.language?.message].filter(err => err).join(', ')}
        adornment={
          <Select showError={false} control={control} rules={{ required: { value: true, message: 'Title language is required' } }} name="language" placeholder="Language" options={primaryLanguagesMap} />
        }
      />
    </div>
  )
}

export default Title