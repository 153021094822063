import React, { forwardRef, useState } from "react";
import { UseFormRegister } from "react-hook-form";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { UserPasswordChange } from "types/form";
import { InputProps } from "types/Input";

const PasswordInput = forwardRef<
  HTMLInputElement,
  InputProps & ReturnType<UseFormRegister<UserPasswordChange>>
>((props, ref) => {
  const { label, labelAlt, className, errorText, autoComplete = "password", ...rest1 } = props;
  const { labelClassName, tooltip, ...rest } = rest1;
  const [showPassword, setShowPassword] = useState<boolean>(false);
  return (
    <div className="input-box w-full">
      <div className="form-control w-full">
        {label || labelAlt ? (
          <label className="label">
            <span className="label-text">{label}</span>
            <span className="label-text-alt">{labelAlt}</span>
          </label>
        ) : null}
        <div className="w-full relative">
          <input
            className={`w-full input input-bordered ${className ?? ""}`}
            {...rest}
            ref={ref}
            type={showPassword ? "text" : "password"}
            autoComplete={autoComplete}
          />
          <div className="absolute right-3 top-3">
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <AiOutlineEyeInvisible size={22} />
              ) : (
                <AiOutlineEye size={22} />
              )}
            </button>
          </div>
        </div>
      </div>
      {errorText ? (
        <p className="text-error text-xs mt-1">{errorText}</p>
      ) : null}
    </div>
  );
});

export default PasswordInput;
