import { ImageProps } from "types/image";
const { ENV_CLOUDFLARE_PATH } = import.meta.env;

const Image = ({
  height = "auto",
  width = "auto",
  alt = "image",
  className = "",
  cdn = true,
  src,
  style = {},
  ...props
}: ImageProps) => {
  if (cdn && width != "auto" && height != "auto") {
    src = `${ENV_CLOUDFLARE_PATH}/${src}/width=${width},height=${height},fit=crop`;
  } else if (cdn && width != "auto") {
    src = `${ENV_CLOUDFLARE_PATH}/${src}/width=${width},fit=crop`;
  } else if (cdn && height != "auto") {
    src = `${ENV_CLOUDFLARE_PATH}/${src}/height=${height},fit=crop`;
  } else if (cdn) {
    src = `${ENV_CLOUDFLARE_PATH}/${src}/public`;
  }

  return (
    <img
      src={src}
      alt={alt}
      height={height}
      width={width}
      className={`${className}`}
      style={style}
      {...props}
    />
  );
};

export default Image;
