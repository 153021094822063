import { MultiRegionOption } from './RegionSelector';
import Regions from './region.dataset.json';

export const getUnselectedRegions = (selectedRegions: string[]): string[] => {
  const flatCountries: MultiRegionOption = [];

  Regions.forEach(region => {
    if (region.value) {
      flatCountries.push(region)
    } else if (region.options) {
      region.options.forEach(country => flatCountries.push(country))
    }
  });

  const unselectedCountries = flatCountries.map(c => c.value).filter(c => c && !selectedRegions.includes(c)) as string[];

  return unselectedCountries;

}

export const getSelectedRegions = (unselectedRegions: string[] | undefined): string[] => {
  const flatCountries: MultiRegionOption = [];

  Regions.forEach(region => {
    if (region.value) {
      flatCountries.push(region)
    } else if (region.options) {
      region.options.forEach(country => flatCountries.push(country))
    }
  });

  if (!unselectedRegions?.length) {
    return []
  }

  const count = flatCountries.map(c => c.value).filter(c => c && !unselectedRegions.includes(c))
  return count as string[]
}