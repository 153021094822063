import TextArea from "@components/Inputs/TextArea"
import { Controller, useFormContext } from "react-hook-form";

const Description = () => {
  const { control } = useFormContext();
  return (
    <div>
      <Controller name="description" render={({ field }) => <TextArea {...field} label="Release Description" placeholder="Description" labelClassName="font-semibold" />} />
    </div>
  )
}

export default Description