import DateInput from "@components/Inputs/DateInput"
import { format, parseISO } from "date-fns";
import { Controller, useFormContext } from "react-hook-form";

const ReleaseDate = () => {
  const { control } = useFormContext();
  return (
    <div className="flex justify-between items-center gap-4">
      <Controller
        name="release-date"
        control={control}
        defaultValue={new Date().toISOString().split('T')[0]}
        render={
          ({ field }) => (
            <DateInput label="Release Date" labelClassName="font-semibold" {...field} value={field.value && format(parseISO(field.value), 'yyyy-MM-dd')} />
          )
        }
      />
      <Controller
        name="digital-release-date"
        control={control}
        defaultValue={new Date().toISOString().split('T')[0]}
        render={
          ({ field }) => (
            <DateInput label="Digital Release Date" labelClassName="font-semibold" {...field} value={format(parseISO(field.value), 'yyyy-MM-dd')} />
          )}
      />
    </div>
  )
}

export default ReleaseDate