import { Control, Controller } from "react-hook-form";
import RegionSelectorCore from "./RegionSelectorCore";

type RegionSelectorProps = {
  control?: Control<any>;
  name: string;
  label?: string;
}

export const RegionSelector = (props: RegionSelectorProps) => {

  return (
    <div className="flex flex-col">
      {props.label ? <label className="label-text mb-2">{props.label}</label> : null}
      <Controller
        name={props?.name}
        control={props?.control}
        rules={{ required: { value: true, message: 'Please select a region' } }}
        render={
          ({ field, fieldState: { error } }) => (
            <>
              <RegionSelectorCore onChange={field.onChange} type='unselected' values={field.value} />

              {error ? <p className="text-error text-sm mt-1">{error.message}</p> : null}
            </>
          )
        }
      />
    </div>
  )

}