import { SelectHTMLAttributes, forwardRef, useEffect } from "react";
import ReactSelect, { Props } from "react-select";
import Select from "react-select/dist/declarations/src/Select";

type BaseReactSelectProps<T> = Omit<SelectHTMLAttributes<HTMLSelectElement>, 'value' | 'defaultValue'> & {
  findIn?: T[];
  value?: T;
  defaultValue?: T
}

export type SelectCoreProps = Props & BaseReactSelectProps<Record<'value', unknown>>;

const SelectCore = forwardRef<Select<unknown, boolean>, SelectCoreProps>(({ value, findIn, ...props }: SelectCoreProps, ref) => {
  let newValue = value;

  if (typeof value === 'string' && findIn) {
    newValue = findIn.find(i => i.value === value)
  }

  return (
    <ReactSelect
      {...props}
      value={newValue}
      ref={ref}
    />
  )
})

export default SelectCore