import React, { ReactNode } from 'react'

const Group = ({ title, children }: { title: string, children?: ReactNode | ReactNode[] }) => {
  return (
    <div>
      {/* add shadow when it is sticking */}
      <h3 className='sticky top-0 left-0 z-10 bg-base-100 py-4 pl-5 text-lg w-full font-semibold'>{title}</h3>
      <div className='p-4 flex flex-col gap-4'>
        {children}
      </div>
    </div>
  )
}

export default Group