import Image from "@components/Image";
import { useLocation, useParams } from "react-router-dom"
import { twMerge } from "tailwind-merge";
import { ExplicitLyrics, ReleaseStatus, ValueTypes } from "zeus";
import { useReleaseById } from "hooks/api/useRelease";
import './release.css';
import { primaryGenres } from "constants/Genre/primary";
import { secondaryGenres } from "constants/Genre/secondary";
import { useSongByReleaseId } from "hooks/api/useSong";
import { useEffect } from "react";
import Advisory from "@utils/advisory";
import durationFormat from "@utils/duration-format";
import { Link } from "react-router-dom";
import { HiPencilAlt } from "react-icons/hi";
import Button from "@components/Button";
import ReleaseTypeMap from "constants/ReleaseTypeMap";
import { useGraphqlMutation } from "hooks/useGraphql";
import { toast } from 'react-toastify'
const Release = () => {
  const { id } = useParams() as { id: string };
  const location = useLocation();
  const { data, isLoading, refetch } = useReleaseById(id);
  const { data: songsData, get, isLoading: isLoadingSong } = useSongByReleaseId();
  let release: ValueTypes['ReleaseOutput'] = data?.getReleaseById || location.state?.release;
  const { mutate, isLoading: submitIsLoading } = useGraphqlMutation()

  useEffect(() => {
    if (release?.id) {
      get(release.id as string);
    }
  }, [release?.id])

  useEffect(() => {
    refetch()
    if (release?.id) {
      get(release.id as string)
    }
  }, [location.key])

  const submitRelease = async () => {
    try {
      const res = await mutate({ updateRelease: [{ input: { id: release.id as string, status: ReleaseStatus.SUBMITTED } }, { id: true }] })
      if (res.data?.updateRelease?.id) {
        await refetch()
        toast.success("Release submitted successfully");
      } else {
        toast.error('Error submitting release')
      }
    } catch (error) {
      toast.error('Error submitting release')
    }
  }

  if (isLoading || isLoadingSong) {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <p>Please wait...</p>
      </div>
    );
  }
  if (!release) {
    return null
  }

  const publishingOwners = `${release.licence?.legal_owner_of_release?.year} ${release.licence?.legal_owner_of_release?.name}`;
  const rightsOwner = `${release.licence?.legal_owner_of_work?.year} ${release.licence?.legal_owner_of_work?.name}`;

  const original_release_date = release?.licence?.original_release_date ? new Date(release.licence?.original_release_date as string).toLocaleDateString() : '-';
  const digital_release_date = release?.licence?.digital_release_date ? new Date(release.licence?.digital_release_date as string).toLocaleDateString() : '-';


  return (
    <div className="pl-20 pr-10 pt-12">
      <div className="flex">
        <Image className="w-56 h-56 rounded-lg bg-black" src={release?.release_info?.cover_art as string} />
        <div className="ml-8 w-full flex flex-col">
          <div className="flex justify-between w-full items-center">
            <h1 className="text-2xl font-bold">{release?.title}{release?.release_info?.version ? ` - ${release?.release_info?.version}` : ''}</h1>
            <div className="flex gap-4">
              <Link to={`/releases/edit/${id}`} state={{ backgroundLocation: location, release, songs: songsData }} className="btn btn-ghost">
                <HiPencilAlt size={22} className="inline-block mr-2" />
                <span>Edit Release</span>
              </Link>

              {release.status === ReleaseStatus.DRAFT ? (
                <Button title='This will start a verification process. Once verified it will be available for use' onClick={submitRelease} disabled={submitIsLoading}>Submit</Button>
              ) : (
                <Button disabled className="disabled:!bg-orange-500 disabled:!opacity-100 disabled:!text-white">
                  <p>{release.status}</p>
                </Button>
              )}
            </div>
          </div>
          <div className="flex mt-4 justify-between">
            <div className="flex flex-col gap-0.5">
              <p className="text-sm mt-0.5">Primary Artist: {release.release_info?.artists?.[0]?.name}</p>
              <p className="text-sm mt-0.5">Artists & Contributors: {songsData?.length ? [...new Set(songsData?.map(song => song.artists).flat(1).map(artist => artist?.name))].join(', ') : 'Unavailable'}</p>
              <p className="text-sm mt-0.5">Label: {release.release_info?.label}</p>
              <p className="text-sm mt-0.5">Type: {ReleaseTypeMap.find(t => t.value === release.release_info?.release_type)?.label}</p>
              <p className="text-sm mt-0.5">Genres: {[primaryGenres.find(g => g.value === release.release_info?.primary_genre)?.label, secondaryGenres.find(g => g.value === release.release_info?.secondary_genre)?.label].filter(i => i).join(', ')}</p>
              <p className="text-sm mt-0.5">Owners: {rightsOwner} (Rights), {publishingOwners} (Publishing)</p>
              <p className="text-sm mt-0.5">Advisory: {songsData?.length ? ([...new Set(songsData?.map((s) => s.explicit_lyrics))].length === 1 ? Advisory[songsData[0].explicit_lyrics as ExplicitLyrics] : 'Mixed') : 'Unknown'}</p>
            </div>
            <div className="text-right">
              <p className="text-sm mt-0.5">Original Release Date: ({original_release_date})</p>
              <p className="text-sm mt-0.5">Digital Release Date: ({digital_release_date})</p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <h1 className="text-lg font-bold">Songs</h1>
        <table className="mt-4 w-full">
          <tbody>

            {!songsData?.length ? (

              <div className="text-sm flex justify-center items-center">
                <p>No songs found</p>
              </div>
            ) : null}
            {songsData?.map((song, index) => (
              <tr key={song?.id as string} className={twMerge(`text-sm h-12 group`, songsData.length % 2 === 0 ? 'even:bg-slate-100  ' : 'odd:bg-slate-100')}>
                <td className="font-semibold p-4 py-0">{index + 1}. {song?.title}</td>
                <td className="hidden sm:table-cell">{song?.artists?.map(artist => artist?.name).join(', ')}</td>
                <td>{Advisory[song.explicit_lyrics as ExplicitLyrics]}</td>
                <td>{durationFormat((song?.duration || 0) as number)}</td>
                <td>{song.licence_type}</td>
                <td>{song.available_separately ? 'Separately Available' : ''}</td>
                {/* <td className="focus-visible-cell group">
                  <div className="space-x-4 items-center flex">
                    <button className="song-play-btn">
                      <IoIosPlayCircle size={28} />
                    </button>
                    <button className="song-edit-btn">
                      <IoPencil size={18} />
                    </button>
                  </div>
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Release