import Button from "@components/Button";
import Image from "@components/Image";
import BaseInput from "@components/Inputs/BaseInput";
import PasswordInput from "@components/Inputs/Password";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { RegisterProps } from "types/form";
import useAuth from "hooks/useAuth";
import Checkbox from "@components/Inputs/Checkbox";
import { getCountryPhoneCodeFieldOptions } from "@utils/country";
import Select from "@components/Inputs/Select";

const Register = () => {
  const navigate = useNavigate();

  const { register, handleSubmit, watch, control, formState } =
    useForm<RegisterProps>();
  const isTncAgreed = watch("tnc");
  const { errors } = formState;
  const { isLoading, error, signUp } = useAuth();
  const onSubmit = handleSubmit(async (formData) => {
    try {
      if (formData.phone) {
        formData.phone = `${formData?.country_code?.value}${formData.phone}`;
      }
      const response = await signUp(formData);

      if (response) {
        navigate("/verify", {
          state: {
            username: response.user.getUsername(),
            phone: formData.phone,
            email: formData.email,
            password: formData.password,
          },
        });
      }
    } catch (err: any) {
      console.log(err);
    }
  });

  console.log(!isTncAgreed, isLoading);
  return (
    <div className="flex justify-center items-center h-screen flex-col">
      <Image src="ziki-logo-svg" height="100px" width="100px" />
      <form className="form flex flex-col gap-2 w-96" onSubmit={onSubmit}>
        <h3 className="text-2xl mt-2 mb-2 text-center">Register</h3>
        {error && (
          <div className="w-full">
            <div className="text-error text-sm">{error}</div>
          </div>
        )}
        <BaseInput
          placeholder="Name"
          className="w-full"
          type="text"
          {...register("name", { required: true })}
          label="Name"
        />
        <BaseInput
          type="email"
          placeholder="Email"
          label="Email"
          className="w-full"
          {...register("email", { required: true })}
        />
        <label className="label pb-0">
          <span className="label-text">Country</span>
        </label>
        <div className="flex gap-2 items-center">
          <Select
            name="country_code"
            className="custom-select w-full"
            options={getCountryPhoneCodeFieldOptions()}
            // @ts-ignore
            control={control}
            isMulti={false}
            isClearable={false}
            rules={{
              required: {
                value: true,
                message: "Please select country phone code!",
              },
            }}
          />
          <BaseInput
            type="text"
            placeholder="Phone Number"
            className="w-full"
            {...register("phone", { required: true })}
          />
        </div>
        <PasswordInput
          placeholder="Password"
          className="w-full"
          label="Password"
          type="password"
          autoComplete="new-password"
          {...register("password", {
            required: true,
            pattern: {
              message:
                "Password must contain uppercase, lowercase, digits and symbols",
              value:
                /(?=^.{6,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).*/,
            },
            minLength: {
              value: 6,
              message: "Password must be of minimum 6 characters!",
            },
          })}
          errorText={errors?.password?.message}
        />
        <Checkbox
          label={
            <label
              htmlFor="tnc"
              className="label-text text-base-content text-sm cursor-pointer"
            >
              I agree to the{" "}
              <Link to="/tnc" className="underline">
                terms & conditions
              </Link>
            </label>
          }
          control={control as any}
          {...register("tnc", { required: true })}
        />
        <Button className="mt-3 w-full" disabled={!isTncAgreed || isLoading}>
          {isLoading ? "Loading..." : "Register"}
        </Button>
      </form>
    </div>
  );
};

export default Register;
