import { useGraphqlMutation, useGraphqlQuery } from 'hooks/useGraphql'
import VideoCard from './VideoCard'
import { useAppSelector } from 'hooks'
import { useRef, useState } from 'react';
import Modal from '@components/Modal';

const VideosList = () => {
  const user = useAppSelector(state => state.auth.details);
  const { mutate } = useGraphqlMutation();
  const previewVideoUrl = useRef<HTMLIFrameElement | null>(null);
  const [previewVideo, setPreviewVideo] = useState<boolean>(false)
  const { data, refetch } = useGraphqlQuery({
    listAllVideosByArtist: [
      {
        input: { artistId: user?.username ?? '' }
      },
      {
        videos: {
          id: true,
          title: true, description: true, streamId: true
        }
      }]
  });

  const onDelete = async (id: string) => {
    const deleteVideoRes = await mutate({ deleteVideo: [{ id }, { success: true }] })
    if (deleteVideoRes.data?.deleteVideo?.success) {
      refetch()
    }
  }

  return (
    <div className='container grid-container 2xl:!grid-cols-3 gap-2 mt-3'>
      {data?.listAllVideosByArtist?.videos?.map(video =>
        <VideoCard
          key={video.id}
          title={video.title ?? ''}
          id={video.streamId ?? ''}
          description={video.description ?? ''}
          onDelete={() => onDelete(video.id!)}
          onOpen={(id) => {
            setPreviewVideo(true)
            if (previewVideoUrl.current) previewVideoUrl.current.src = `https://customer-pg79d6x5qysbf96p.cloudflarestream.com/${id}/iframe`
          }}
        />
      )}

      <Modal visible={previewVideo} id='preview-video-modal' onClose={() => { if (previewVideoUrl.current) previewVideoUrl.current.src = ''; setPreviewVideo(false) }} className='max-w-none'>
        <div className='w-full h-full flex items-center justify-center'>
          <iframe
            ref={previewVideoUrl}
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
            className='h-full w-full'
            allowFullScreen
          ></iframe>
        </div>
      </Modal>
    </div >
  )
}

export default VideosList