import { FC, forwardRef, InputHTMLAttributes } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { twMerge } from "tailwind-merge";


export type BaseCheckboxProps = InputHTMLAttributes<HTMLInputElement> & Omit<ControllerProps, 'render'> & {
  label: string;
  onChange?: (checked: boolean) => void;
}

const CheckboxCore = forwardRef<HTMLInputElement, BaseCheckboxProps>((props, ref) => {
  const { label, value, checked = false, disabled, onChange, className, name, ...rest } = props;
  return ((
    <label className={twMerge(`form-control flex flex-row items-center w-full cursor-pointer gap-2 mt-2`, disabled && 'text-slate-500')} htmlFor={name}>
      <input
        type="checkbox"
        className={twMerge(`checkbox checkbox-sm`, className)}
        {...rest}
        ref={ref}
        name={name}
        value={value}
        id={name}
        disabled={disabled}
        defaultChecked={checked || !!value}
      />

      {label}
    </label>
  ));
})


const Checkbox = forwardRef<HTMLInputElement, BaseCheckboxProps>((props, ref) => {
  if (!props.control) {
    return <CheckboxCore {...props} />
  }

  return (
    <Controller
      defaultValue={false}
      {...props}
      render={({ field }) => {
        return <CheckboxCore checked={field.value} onClick={(e) => field.onChange((e.target as any).checked)} ref={ref} {...props} />
      }}
    />
  )
})

export default Checkbox as FC<BaseCheckboxProps>;
