import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";

export interface DropdownMenuProps {
  children: React.ReactNode;
  className?: string;
  menuItems: Array<{
    label: string;
    icon?: JSX.Element;
    action?: (e: React.MouseEvent<HTMLDivElement>) => void;
  }>;
  position?: "top-10" | "bottom-10";
  direction?: "left-1" | "right-1";
}

const DropdownMenu = ({
  children,
  className,
  menuItems,
  position = "top-10",
  direction = "right-1",
}: DropdownMenuProps) => {
  return (
    <Menu as="div" className={"relative " + className}>
      <Menu.Button>{children}</Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`z-50 absolute ${position} ${direction} origin-${position}-${direction} rounded-md bg-base-200 shadow-lg ring-1 focus:outline-none w-max overflow-clip backdrop-blur-md`}
        >
          <div className="flex flex-col divide-y divide-base-300">
            {menuItems.map((item) => (
              <Menu.Item key={item.label}>
                <div
                  className="p-2 hover:bg-base-300 cursor-pointer"
                  onClick={item?.action}
                >
                  <a className="pr-1 flex items-center">
                    <span className="inline-block mr-2">{item?.icon}</span>
                    {item.label}
                  </a>
                </div>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default DropdownMenu;
